import React, { useState, useEffect } from "react"
import { Row, Col, Card, Table, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import moment from "moment"
import HyperDatepicker from "../../../components/Datepicker"
import swal from "sweetalert"
import callApi from "../../../utils/apiCaller"
import { FormInput } from "../../../components"

const BookingDetailsInOnDemand = (props) => {
  const data = props.data ? props.data : {}
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [signInModal, setSignInModal] = useState(false)
  const [dateOfBirth, setDateOfBirth] = React.useState(
    new Date(data?.service_start_date || new Date())
  )
  const [isModalVisible, setModal] = useState(false)
  const [reason, setReason] = useState("")
  const [scheduleDate, setScheduleDate] = React.useState(null)
  const [timeSlots, setTimeSlots] = React.useState([])
  const [scheduleTime, setScheduleTime] = React.useState(null)
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState(null)

  const getTimeSlots = () => {
    if (data.servicepackageId._id) {
      callApi(`servicebooking/gettimeslot`, "POST", {
        servicepackageId: data.servicepackageId._id,
      }).then((res) => {
        if (res.data) {
          setTimeSlots(res.data)
        }
      })
    }
  }

  useEffect(() => {
    getTimeSlots()
  }, [])

  const scheduleBooking = () => {
    console.log(
      "data.isScheduled",
      moment(scheduleTime, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
      scheduleTime,
      moment(scheduleDate).format("YYYY-MM-DD")
    )
    if (data._id && scheduleDate && selectedTimeSlot) {
      const info = {
        servicebookingId: data._id,
      }
      if (data.isScheduled) {
        info.rescheduled_date = moment(scheduleDate).format("YYYY-MM-DD")
        info.rescheduled_timeSlot = data?.serviceType != "on-demand" ? selectedTimeSlot : moment(selectedTimeSlot, 'HH:mm').diff(moment().startOf('day'), 'minutes')
        info.serviceType = data?.serviceType;
      }
      if (!data.isScheduled) {
        info.service_start_date = moment(scheduleDate).format("YYYY-MM-DD")
        info.timeSlot = selectedTimeSlot
      }
      const url = data?.isScheduled
        ? "ondemand/reschedule"
        : "ondemand/toschedule"
      callApi(url, "POST", info).then((res) => {
        if (res.success) {
          console.log("res____ res", res)
          setSignInModal(false)
          props.getData(props.data._id)
          setScheduleDate(null)
          getTimeSlots()
          swal("Success", "Booking scheduled successfully", "success")
        } else {
          setSignInModal(false)
          swal("Error", res.message, "error")
        }
      })
    }
  }

  const deactivateService = () => {
    swal({
      title: "Cancel this booking?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const info = {
          servicebookingId: data._id,
          cancellation_reason: reason,
        }
        console.log("info_____", info)
        callApi("ondemand/tocancel", "POST", info).then((res) => {
          if (res.success) {
            setModal(false)
            props.getData(props.data._id)
            setReason("")
            swal("Success", "Booking updated successfully", "success")
          } else {
            setModal(false)
            swal("Error", res.message, "error")
          }
        })
      }
    })
  }

  return (
    <>
      <Card>
        <Card.Body>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className=""
          >
            <Col>
              <h4 className="">Booking details</h4>
            </Col>
            <div>
              {!data?.isCancelled && (
                <button
                  className="btn btn-danger btn-block"
                  onClick={() => {
                    setModal(!isModalVisible)
                  }}
                >
                  <i className="mdi mdi-account-remove mr-1"></i>Cancel Booking
                </button>
              )}
            </div>
          </div>
          <Row>
            <Col xl={6}>
              <div className="border p-3 mt-4 mt-lg-0 rounded">
                <Table responsive className="mb-0">
                  <tbody>
                    <tr>
                      <td>Package amount :</td>
                      <td>
                        <i className="mdi mdi-currency-inr"></i>
                        <strong>{data?.booking_cost?.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Platform Cost :</td>
                      <td>
                        <i className="mdi mdi-currency-inr"></i>
                        <strong>{data?.platform_cost?.toFixed(2)}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Taxes ({data?.servicepackageId?.gstCharges}%) :</td>
                      <td>
                        <i className="mdi mdi-currency-inr"></i>
                        <strong
                          className=""
                          style={{
                            color: "green",
                          }}
                        >
                          +{data?.tax?.toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>Discount </span>
                        <span className="text-red" style={{ color: "red" }}>
                          ({data?.discount_percentage}%) :
                        </span>
                      </td>
                      <td>
                        <i className="mdi mdi-currency-inr"></i>
                        <strong className="text-red" style={{ color: "red" }}>
                          -{data?.discount_amount?.toFixed(2)}
                        </strong>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Amount after discount :</td>
                      <td>
                        <i className="mdi mdi-currency-inr"></i>
                        <strong>{data?.nonGstPrice?.toFixed(2)}</strong>
                      </td>
                    </tr> */}
                    <tr>
                      <td>
                        <strong>Total amount :</strong>
                      </td>
                      <td>
                        <i className="mdi mdi-currency-inr"></i>
                        <strong
                          className="text-red text-uppercase"
                          style={{ fontSize: "16px", color: "black" }}
                        >
                          {Math.ceil(data?.final_booking_cost)}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Partner Cost :</strong>
                      </td>
                      <td>
                        <i className="mdi mdi-currency-inr"></i>
                        <strong
                          className="text-red text-uppercase"
                          style={{ fontSize: "16px", color: "black" }}
                        >
                          {Math.ceil(data?.partner_cost)}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={6}>
              <>
                <div className="table-responsive">
                  <table className="table mb-0">
                    <tbody>
                      <tr>
                        <td>Service Name</td>
                        <td>{data?.servicepackageId?.service_name}</td>
                      </tr>
                      <tr>
                        <td>Booking Number</td>
                        <td>{data?.booking_num}</td>
                      </tr>
                      <tr>
                        <td>Service Booking Date</td>
                        <td>
                          {moment(data?.service_booking_date).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Service Scheduled Date</td>
                        <td>
                          {data?.isCancelled ? (
                            <>
                              {moment(data?.service_start_date).format(
                                "DD-MM-YYYY"
                              )}
                            </>
                          ) : (
                            <>
                              {data?.isScheduled ? (
                                <>
                                  {moment(data?.service_start_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                  {data?.serviceType == "on-demand" ?
                                    <small className="text-muted ms-1">
                                      {moment().startOf('day').add(data?.serviceMinute, 'minutes').format(
                                        "LT"
                                      )}
                                    </small>
                                    :
                                    null}
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setSignInModal(!signInModal)}
                                    className="mdi mdi-square-edit-outline btn btn-success btn-block  ms-2 "
                                  >
                                    <i style={{}}></i>
                                    Reschedule
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#00a8ff",
                                    }}
                                    onClick={() => setSignInModal(!signInModal)}
                                  >
                                    <i className="mdi mdi-square-edit-outline btn btn-success btn-block  ">
                                      Schedule
                                    </i>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Service Expiry Date</td>
                        <td>
                          {data?.expiry_date && (
                            <>
                              {moment(data?.expiry_date).format("DD MMM, YYYY")}
                            </>
                          )}
                        </td>
                      </tr>
                      {data?.isCancelled && (
                        <tr>
                          <td>Cancelled Date</td>
                          <td>
                            {moment(data?.cancelledOn).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                      )}
                      {data?.isCancelled && (
                        <tr>
                          <td>Cancelled Reason</td>
                          <td>{data?.cancellation_reason}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {isEditMode && (
                  <div className="text-end">
                    <button
                      className="btn btn-danger mt-2"
                      style={{ marginRight: "20px" }}
                      onClick={() => setIsEditMode(false)}
                    >
                      <i className="mdi mdi-content-save"></i>Cancel
                    </button>
                    <button
                      className="btn btn-success mt-2 pl-2 "
                      onClick={() => { }}
                    >
                      <i className="mdi mdi-content-save"></i> Save
                    </button>
                  </div>
                )}
              </>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Modal
        show={isModalVisible}
        onHide={() => setModal(!isModalVisible)}
        size={"lg"}
      >
        <Modal.Body>
          <div className="text-center mt-2 mb-4">
            <h4>Cancel Reason</h4>
            <div className="text-center mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Reason"
                onChange={(e) => {
                  setReason(e.target.value)
                }}
                value={reason}
              />
              <button
                onClick={() => {
                  deactivateService()
                }}
                className="btn btn-rounded btn-primary mt-3"
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={signInModal} onHide={() => setSignInModal(!signInModal)}>
        <Modal.Body>
          <div className="text-center mt-2 mb-4">
            <h4>Update Booking Status</h4>
            <div className="text-center">
              <HyperDatepicker
                hideAddon={true}
                value={scheduleDate}
                onChange={(date) => {
                  setScheduleDate(date)
                }}
              />
              {data?.serviceType != "on-demand" ?
                <FormInput
                  label="Select timeslot"
                  type="select"
                  name="partnerStatus"
                  placeholder="Partner Status"
                  key="partnerStatus"
                  value={scheduleTime}
                  onChange={(e) => {
                    setScheduleTime(e.target.value)
                    setSelectedTimeSlot(e.target.value)
                  }}
                >
                  <option>Select time slot</option>
                  {timeSlots.map((time, index) => (
                    <option key={index} value={time._id}>
                      {time.name}
                    </option>
                  ))}
                </FormInput>
                :
                <FormInput
                  type="time"
                  label="Select time"
                  name="time"
                  key="partnerStatus"
                  value={scheduleTime}
                  onChange={(e) => {
                    setScheduleTime(e.target.value)
                    setSelectedTimeSlot(e.target.value)
                  }}
                />
              }
            </div>
            <div className="mb-3 text-center mt-4">
              <button
                onClick={() => {
                  scheduleBooking()
                }}
                className="btn btn-rounded btn-primary"
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default BookingDetailsInOnDemand
