import {Navigate} from "react-router-dom"
import {AuthActionTypes} from "../redux/auth/constants"
import {useSelector, connect} from "react-redux"
import {getUser} from "../redux/auth/reducers"

const Root = (props) => {
  const {logged_in_user_details} = useSelector((state) => ({
    logged_in_user_details: state.AppState.logged_in_user_details,
  }))

  if (logged_in_user_details === null) {
    return <Navigate to="account/login" />
  } else {
    return <Navigate to="support/open-tickets" />
  }

  const getRootUrl = () => {
    let url = "partners/leader-board"
    return url
  }

  const url = getRootUrl()

  return <Navigate to={`/${url}`} />
}

export default Root
