import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Card, Col, Offcanvas, Row } from "react-bootstrap"
import callApi from "../../../utils/apiCaller"
import DoorstepRequestAcceptedTable from "./DoorstepRequestAcceptedTable"
import DoorstepRequestUserDetails from "./DoorstepRequestUserDetails"
import DoorstepOtherPartnersList from "./DoorstepOtherPartnersList"
import swal from "sweetalert"

const DoorstepServiceRequestDetails = (props) => {
  const [data, setData] = React.useState(null)
  const [searchedPartners, setSearchedPartners] = useState([]);

  const getSearchedPartners = (searchText) => {
    callApi(`ondemand/getcleaners`, "POST", {
      pageNum: 1,
      pageSize: 20,
      search: searchText,
    }).then((res) => {
      if (res.data) {
        setSearchedPartners(res.data)
      }
    })
  }

  const assignPartner = (partnerId) => {
    swal({
      title: "Are you sure ?",
      text: "You want to assign a new partner",
      icon: "warning",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
          const info = {}
          info.servicebookingId = props?.bookingId
          info.assigned_cleaner_id = partnerId
          callApi(`ondemand/assignDoorStep`, "POST", info).then((res) => {
            if (res.success) {
              swal("Success", "Partner assigned successfully", "success")
              props?.updateBookingDetails(props?.bookingId);
            } else {
              swal("Error", res.message, "error")
            }
          })
        }
    })
  }

  return (
    <div>
      <Offcanvas
        show={true}
        onHide={() => props.setShow(false)}
        placement="bottom"
        style={{
          marginTop: 30,
          borderTopLeftRadius: 14,
          borderTopRightRadius: 14,
          width: "100%",
          height: "95%",
          backgroundColor: "#FAFBFE",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: -20,
            right: "50%",
            padding: 0,
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <div
            style={{
              backgroundColor: "#3b3c36",
              borderRadius: 300,
              height: 40,
              width: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="dripicons-cross text-white"
              style={{ fontSize: 20 }}
              onClick={() => props.setShow(false)}
            ></i>
          </div>
        </span>
        <Offcanvas.Body style={{ paddingTop: 0 }}>
          <Row className="mt-4">
            <Col xs={2}></Col>
            <Col xs={8}>
              {/* <div>
                {data && (
                  <DoorstepRequestUserDetails
                    data={data}
                    list={list.completed}
                  />
                )}
              </div> */}
              <div>
              <DoorstepOtherPartnersList
                  data={searchedPartners}
                  name={"Other Partners"}
                  assignPartner={assignPartner}
                  activePartner={props?.activePartner}
                  handleSearch={getSearchedPartners}
                />
                {props?.availAblePartner?.completed && (
                  <>
                    <DoorstepRequestAcceptedTable
                      data={props?.availAblePartner?.completed || []}
                      name="Assigned Requests"
                      updateStatus={props?.updateStatus}
                      bookingIsClosed={props?.bookingIsClosed}
                      activePartner={props?.activePartner}
                    />
                  </>
                )}
                {props?.availAblePartner?.pending && (
                  <>
                    <DoorstepRequestAcceptedTable
                      data={props?.availAblePartner?.pending || []}
                      name="Pending Requests"
                      updateStatus={props?.updateStatus}
                      bookingIsClosed={props?.bookingIsClosed}
                      activePartner={props?.activePartner}
                    />
                  </>
                )}
                {props?.availAblePartner?.cancelled && (
                  <>
                    <DoorstepRequestAcceptedTable
                      data={props?.availAblePartner?.cancelled || []}
                      name="Rejected/Closed Requests"
                      updateStatus={props?.updateStatus}
                      bookingIsClosed={props?.bookingIsClosed}
                      activePartner={props?.activePartner}
                    />
                  </>
                )}
              </div>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default DoorstepServiceRequestDetails
