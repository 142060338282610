import React, {useState, useEffect} from "react"
import {Col, Form, InputGroup, Modal, Card} from "react-bootstrap"
import {Link} from "react-router-dom"
import callApi from "../../../utils/apiCaller"
import Table from "../../../components/Table"
import _ from "lodash"
import swal from "sweetalert"

const sizePerPageList = [
  {
    text: "50",
    value: 50,
  },
]

const PartnerDetailsInBookings = (props) => {
  const data = props.data.assigned_cleaner_id
    ? props.data.assigned_cleaner_id
    : null
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [signInModal, setSignInModal] = useState(false)
  const [partners, setPartners] = React.useState(null)
  const [searchInput, setSearchInput] = useState("")

  const toggleSignIn = () => {
    setSignInModal(!signInModal)
  }

  const getLiftOfPartner = () => {
    if (props.data._id) {
      callApi(`servicebooking/getcleaner`, "POST", {
        servicebookingId: props.data._id,
      }).then((res) => {
        if (res.success) {
          setPartners(res.data)
        } else {
        }
      })
    }
  }

  useEffect(() => {
    getLiftOfPartner()
  }, [])

  const getSearchedPartners = (searchText) => {
    if (searchText) {
      callApi("servicebooking/getcleaner", "POST", {
        pageNum: 1,
        pageSize: 20,
        search: searchText,
      }).then((res) => {
        if (res.data) {
          setPartners(res.data)
        }
      })
    } else {
      getLiftOfPartner()
    }
  }

  const ActionColumn = ({row}) => {
    const URL = props.data.isSubscriptionModel
      ? "servicebooking/updatecleaner"
      : "ondemand/assign"
    return (
      <>
        <div
          style={{
            cursor: "pointer",
          }}
          className="action-icon"
          onClick={() => {
            if (props.data._id) {
              callApi(URL, "POST", {
                servicebookingId: props.data._id,
                assigned_cleaner_id: row.original._id,
              }).then((res) => {
                if (res.success) {
                  toggleSignIn()
                  swal("Success", "Partner assigned successfully", "success")
                  props.getData(props.data._id)
                } else {
                  swal("Error", res.message, "error")
                }
              })
            }
          }}
        >
          {" "}
          <i
            className="mdi mdi-square-edit-outline btn btn-success btn-block"
            style={{fontSize: "16px"}}
          >
            Assign
          </i>
        </div>
      </>
    )
  }

  const NameColumn = ({row}) => {
    return (
      <div
        className="table-user"
        onClick={() => {
          if (props.data._id) {
            callApi(URL, "POST", {
              servicebookingId: props.data._id,
              assigned_cleaner_id: row.original._id,
            }).then((res) => {
              if (res.success) {
                toggleSignIn()
                swal("Success", "Partner assigned successfully", "success")
                props.getData(props.data._id)
              } else {
                swal("Error", res.message, "error")
              }
            })
          }
        }}
      >
        <Link to="#" className="text-body fw-semibold">
          {row.original.name}
        </Link>
      </div>
    )
  }

  const LocationColumn = ({row}) => {
    return (
      <>
        {row?.original?.location?.map((i) => {
          return <span>➣ {i.name}</span>
        })}
      </>
    )
  }

  const ApartmentColumn = ({row}) => {
    return (
      <>
        {row?.original?.servicePlaceNameIds?.map((i) => {
          return <span>➣ {i.name}</span>
        })}
      </>
    )
  }

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      sort: true,
      Cell: NameColumn,
      classes: "table-user",
    },
    {
      Header: "Car count",
      accessor: "carCount",
      sort: true,
    },
    {
      Header: "Bike count",
      accessor: "bikeCount",
      sort: true,
    },
    {
      Header: "City",
      accessor: "city.name",
    },
    {
      Header: "Location",
      Cell: LocationColumn,
    },
    {
      Header: "Apartments",
      Cell: ApartmentColumn,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      classes: "table-action",
      Cell: ActionColumn,
    },
  ]

  return (
    <Card>
      <Card.Body>
        {data ? (
          <>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4 className="header-title mt-2">Partner details</h4>
              {/* {!isEditMode && !props?.data?.isCancelled && (
                <div
                  style={{cursor: "pointer", color: "#00a8ff"}}
                  className="action-icon"
                  onClick={toggleSignIn}
                >
                  <i className="mdi mdi-square-edit-outline action-icon mb-3 btn btn-success btn-block">
                    Reassign Partner
                  </i>
                </div>
              )} */}
            </div>
            <div className="table-responsive">
              <table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>{data?.name}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Number</td>
                    <td>{data?.phone}</td>
                  </tr>
                  <tr>
                    <td>Id</td>
                    <td>{data?.partnerId}</td>
                  </tr>
                  <tr>
                    <td>Assigned Bookings</td>
                    <td>{data?.active_booking_count}</td>
                  </tr>
                  <tr>
                    <td>Otp</td>
                    <td>{data?.otp}</td>
                  </tr>

                  <tr>
                    <td>City</td>
                    <td>{data?.location?.city}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {isEditMode && (
              <div className="text-end">
                <button
                  className="btn btn-danger mt-2"
                  style={{marginRight: "20px"}}
                  onClick={() => setIsEditMode(false)}
                >
                  <i className="mdi mdi-content-save"></i>Cancel
                </button>
                <button
                  className="btn btn-success mt-2 pl-2 "
                  onClick={() => setIsEditMode(false)}
                >
                  <i className="mdi mdi-content-save"></i> Save
                </button>
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="text-center">
              <h4>No partner assigned</h4>
            </div>
          </div>
        )}
        <Modal show={signInModal} onHide={toggleSignIn} size={"xl"}>
          <Modal.Body>
            <div className="text-center mt-2 mb-4">
              <h4>Reassign Partner</h4>
            </div>
            <div className="ps-3 pe-3">
              <Col md={4} className="mb-2">
                <span className="d-flex align-items-center">
                  <InputGroup
                    style={{
                      display: "flex",
                      border: "1px solid #00000050",
                      borderRadius: 4,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Form.Control
                      placeholder="Search...."
                      aria-label="Search...."
                      aria-describedby="basic-addon2"
                      style={{border: 0}}
                      onChange={(e) => {
                        setSearchInput(e.target.value)
                      }}
                      id="search"
                      value={searchInput}
                    ></Form.Control>
                    <div
                      style={{
                        cursor: "pointer",
                        marginRight: 10,
                        paddingLeft: 10,
                      }}
                      onClick={() => {
                        setSearchInput("")
                        getSearchedPartners(searchInput)
                      }}
                    >
                      <i className="mdi mr-1  dripicons-cross"></i>
                    </div>
                    <button
                      className="btn btn-block"
                      style={{
                        backgroundColor: "#2A882A",
                        color: "white",
                      }}
                      onClick={() => getSearchedPartners(searchInput)}
                    >
                      <i className="mdi mr-1"></i> Search
                    </button>
                  </InputGroup>
                </span>
              </Col>
              {partners && (
                <Table
                  columns={columns}
                  data={partners}
                  pageSize={50}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={false}
                  tableClass="table-striped"
                  searchBoxClass="mt-2 mb-3"
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  )
}

export default PartnerDetailsInBookings
