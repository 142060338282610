export const AppStateConstants = {
  LOGGED_IN_USER_DETAILS: "LOGGED_IN_USER_DETAILS",
  CLOSED_TICKET_PAGE_COUNT: "CLOSED_TICKET_PAGE_COUNT",
  OPEN_TICKET_PAGE_COUNT: "OPEN_TICKET_PAGE_COUNT",
  HOLD_TICKET_PAGE_COUNT: "HOLD_TICKET_PAGE_COUNT",
  ON_DEMAND_NEW_BOOKING_PAGE_COUNT: "ON_DEMAND_NEW_BOOKING_PAGE_COUNT",
  ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT",
  ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT",
  ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT:
    "ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT",
  ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT",
  ON_DEMAND_NOT_ASSIGNED_BOOKING_PAGE_COUNT:
    "ON_DEMAND_NOT_ASSIGNED_BOOKING_PAGE_COUNT",
  DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT:
    "DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT",
  DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT:
    "DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT",
  DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT:
    "DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT",
  ALL_USERS_PAGE_COUNT: "ALL_USERS_PAGE_COUNT",
  ALL_PARTNERS_PAGE_COUNT: "ALL_PARTNERS_PAGE_COUNT",
  NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER",
  COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER",
  APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER",
  NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER",
  NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER:
    "NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER",
  SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER: "SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER",
  STARTED_ON_DEMAND_JOB_PAGE_NUMBER: "STARTED_ON_DEMAND_JOB_PAGE_NUMBER",
  COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER: "COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER",
  APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER:
    "APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER",
  NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER: "NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER",
  NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER: "NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER",
  PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER:
    "PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER",
  PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER:
    "PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER",
  PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER:
    "PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER",
  PARTNER_ATTENDENCE_SELECTED_TAB: "PARTNER_ATTENDENCE_SELECTED_TAB",
  DAILY_CLEANING_JOB_SELECTED_TAB: "DAILY_CLEANING_JOB_SELECTED_TAB",
  ON_DEMAND_JOB_SELECTED_TAB: "ON_DEMAND_JOB_SELECTED_TAB",
  REPORTED_DAMAGE_PAGE_NUMBER: "REPORTED_DAMAGE_PAGE_NUMBER",
  COMPLAINT_JOBS_PAGE_NUMBER: "COMPLAINT_JOBS_PAGE_NUMBER",
  SERVICE_PLACES_PAGE_NUMBER: "SERVICE_PLACES_PAGE_NUMBER",
  APARTMENT_DETAILS_SELECTED_TAB: "PARTMENT_DETAILS_SELECTED_TAB",
  SET_CAR_BRANDS_PAGE_NUMBER: "SET_CAR_BRANDS_PAGE_NUMBER",
  SET_CAR_MODELS_PAGE_NUMBER: "SET_CAR_MODELS_PAGE_NUMBER",
  SERVICE_PACKAGE_PAGE_NUMBER: "SERVICE_PACKAGE_PAGE_NUMBER",
  SET_BIKE_BRANDS_PAGE_NUMBER: "SET_BIKE_BRANDS_PAGE_NUMBER",
  SET_BIKE_MODELS_PAGE_NUMBER: "SET_BIKE_MODELS_PAGE_NUMBER",
  FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS:
    "FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS",
  ON_DEMAND_NEW_BOOKING_COUNT: "ON_DEMAND_NEW_BOOKING_COUNT",
  REPORTED_JOBS_APPROVED_PAGE_NUMBER: "REPORTED_JOBS_APPROVED_PAGE_NUMBER",
  REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER:
    "REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER",
}
