import React, {useState, useEffect} from "react"
import {Row, Col, Card, OverlayTrigger, Modal} from "react-bootstrap"
import moment from "moment"
import Table from "../../../components/Table"
import _ from "lodash"

const sizePerPageList = [
  {
    text: "50",
    value: 50,
  },
]

const HoldHistory = (props) => {
  const data = props.data || {}
  const [signInModal, setSignInModal] = useState(false)

  console.log("data", data)

  const toggleSignIn = () => {
    setSignInModal(!signInModal)
  }

  useEffect(() => {}, [])

  const StartDate = ({row}) => {
    return (
      row?.original?.start_date && (
        <span>
          <strong>
            {moment(row?.original?.start_date).format("DD MMM, YYYY")}
          </strong>
          <span className="ms-1">
            {moment(row?.original?.start_date).format("HH:MM A")}
          </span>
        </span>
      )
    )
  }
  const StopDate = ({row}) => {
    return (
      row?.original?.stop_date && (
        <span>
          <strong>
            {moment(row?.original?.stop_date).format("DD MMM, YYYY")}
          </strong>
          <span className="ms-1">
            {moment(row?.original?.stop_date).format("HH:MM A")}
          </span>
        </span>
      )
    )
  }

  const columns = [
    {
      Header: "Source",
      accessor: "source",
      sort: true,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: StartDate,
    },
    {
      Header: "Stop Date",
      accessor: "stop_date",
      sort: true,
      Cell: StopDate,
    },
  ]

  return (
    <Card>
      <Card.Body>
        {data.holdDates && (
          <Table
            columns={columns}
            data={data.holdDates || []}
            pageSize={50}
            sizePerPageList={sizePerPageList}
            isSortable={true}
            pagination={true}
            isSearchable={false}
            tableClass="table-striped"
            searchBoxClass="mt-2 mb-3"
          />
        )}
      </Card.Body>
    </Card>
  )
}

export default HoldHistory
