// @flow
import React from "react"
import {Link} from "react-router-dom"
import classNames from "classnames"
import moment from "moment"

/* name column render export */
export const NameColumn = ({row}) => {
  return (
    <div className="table-user">
      <Link
        to={{
          pathname: "/bookings/booking-details",
          search: `?id=${row.original._id}`,
        }}
        className="text-body fw-semibold"
      >
        {row.original.customerId.name}
      </Link>
    </div>
  )
}

/* status column render */
export const StatusColumn = ({row}) => {
  return (
    <>
      {row.original.isActive ? (
        <span className={classNames("badge", "badge-success-lighten")}>
          Active
        </span>
      ) : (
        <span className={classNames("badge", "badge-warning-lighten")}>
          Inactive
        </span>
      )}
    </>
  )
}

/* action column render */
export const ActionColumn = ({row}) => {
  return (
    <>
      <Link
        to={{
          pathname: row.original._id
            ? "/service-places/apartment-details"
            : "/service-places/apartment-details",
          search: `?id=${row.original._id}`,
        }}
        state={{
          partnerId: row.original._id,
        }}
        className="action-icon"
      >
        {" "}
        <i className="mdi mdi-eye"></i>
      </Link>
    </>
  )
}

export const locationColumn = ({row}) => {
  return (
    <>
      <Link
        to={{
          pathname: row.original._id
            ? "/service-places/location-details"
            : "/service-places/location-details",
          search: `?id=${row.original._id}`,
        }}
        state={{
          partnerId: row.original._id,
        }}
        className="action-icon"
      >
        {" "}
        <i className="mdi mdi-eye"></i>
      </Link>
    </>
  )}

export const DateCol = ({row}) => {
  return (
    <span>
      {moment(row?.original?.created_at)
        .utc()
        .utcOffset(330)
        .format("MMMM Do YYYY, h:mm:ss a")}
    </span>
  )
}

export const serDate = ({row}) => {
  return (
    <span>
      {moment(row?.original?.service_start_date).format("DD-MM-YY | HH:MM")}
    </span>
  )
}

export const renderImage = ({row}) => {
  return (
    <>
      {row.original.infoImages.map((i) => (
        <img
          style={{
            height: 80,
            width: 80,
            borderRadius: "2px",
            marginLeft: "5px",
          }}
          src={i}
        />
      ))}
    </>
  )
}

export const resolvedImage = ({row}) => {
  return (
    <>
      {row.original.complaintImages ? (
        <>
          {row.original.complaintImages.map((i) => (
            <img
              style={{
                height: 80,
                width: 80,
                borderRadius: "2px",
                marginLeft: "5px",
              }}
              src={i}
            />
          ))}
        </>
      ) : (
        <p>No Image Available</p>
      )}
    </>
  )
}
export const reportedReasons = ({row}) => {
  return (
    <>
      {row.original.reports.map((i) => {
        return <p>➣ {i}</p>
      })}
    </>
  )
}

export const CityCol = ({row}) => {
  return (
    <>
      <span>{row.original.cityId.name}</span>
    </>
  )
}

export const servicePlaceColumns = [
  {
    Header: "Service Started On",
    accessor: "created_at",
    sort: false,
    Cell: DateCol,
  },
  {
    Header: "Service Place",
    accessor: "name",
    sort: true,
  },
  {
    Header: "Status",
    accessor: "isActive",
    Cell: StatusColumn,
    sort: true,
  },

  {
    Header: "City",
    // accessor: "cityId.name",
    Cell: CityCol,
    sort: true,
  },
  {
    Header: "Location",
    accessor: "locationId.name",
    sort: true,
  },
  {
    Header: "Service Place Type",
    accessor: "type",
    sort: true,
  },

  {
    Header: "Action",
    accessor: "action",
    sort: false,
    Cell: ActionColumn,
  },
]

export const allLocationColumn = [
  {
    Header: "Location",
    accessor: "name",
    sort: true,
  },
  {
    Header: "City",
    accessor: "locationId.city",
    sort: true,
  },

  {
    Header: "Action",
    accessor: "action",
    sort: false,
    Cell: locationColumn,
  },
]

// Name, Phone, City, Locality, Service place type, service place name, vehicle number, vehicle type, booking date, service start date, assigned cleaner name

export const sizePerPageList = [
  {
    text: "50",
    value: 50,
  },
  {
    text: "100",
    value: 100,
  },
]
