import {AppStateConstants} from "./appConstants"

export const setClosePageCount = (closedTicketCount) => {
  return {
    type: AppStateConstants.CLOSED_TICKET_PAGE_COUNT,
    closedTicketCount: closedTicketCount,
  }
}

export const setOpenPageCount = (openTicketPageCount) => {
  return {
    type: AppStateConstants.OPEN_TICKET_PAGE_COUNT,
    openTicketPageCount: openTicketPageCount,
  }
}

export const setHoldPageCount = (holdTicketPageCount) => {
  return {
    type: AppStateConstants.HOLD_TICKET_PAGE_COUNT,
    holdTicketPageCount: holdTicketPageCount,
  }
}

export const setOnDemandNewBookingPageCount = (onDemandNewBookingPageCount) => {
  return {
    type: AppStateConstants.ON_DEMAND_NEW_BOOKING_PAGE_COUNT,
    onDemandNewBookingPageCount: onDemandNewBookingPageCount,
  }
}

export const setOnDemandUnAssignedBookingPageCount = (
  onDemandUnAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT,
    onDemandUnAssignedBookingPageCount: onDemandUnAssignedBookingPageCount,
  }
}

export const setOnDemandAssignedBookingPageCount = (
  onDemandAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT,
    onDemandAssignedBookingPageCount: onDemandAssignedBookingPageCount,
  }
}

export const setOnDemandNotAssignedBookingPageCount = (
  onDemandNotAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT,
    onDemandNotAssignedBookingPageCount: onDemandNotAssignedBookingPageCount,
  }
}

export const setOnDemandInactiveBookingPageCount = (
  onDemandInactiveBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT,
    onDemandInactiveBookingPageCount: onDemandInactiveBookingPageCount,
  }
}

export const setOnDemandCanceledBookingPageCount = (
  onDemandCanceledBookingPageCount
) => {
  return {
    type: AppStateConstants.ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT,
    onDemandCanceledBookingPageCount: onDemandCanceledBookingPageCount,
  }
}

export const setDailyCleaningNewBookingPageCount = (
  dailyCleaningNewBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT,
    dailyCleaningNewBookingPageCount: dailyCleaningNewBookingPageCount,
  }
}

export const setDailyCleaningAssignedBookingPageCount = (
  dailyCleaningAssignedBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT,
    dailyCleaningAssignedBookingPageCount:
      dailyCleaningAssignedBookingPageCount,
  }
}

export const setDailyCleaningInactiveBookingPageCount = (
  dailyCleaningInactiveBookingPageCount
) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT,
    dailyCleaningInactiveBookingPageCount:
      dailyCleaningInactiveBookingPageCount,
  }
}

export const setAllUsersPageCount = (allUsersPageCount) => {
  return {
    type: AppStateConstants.ALL_USERS_PAGE_COUNT,
    allUsersPageCount: allUsersPageCount,
  }
}

export const setAllPartnersPageCount = (allPartnersPageCount) => {
  return {
    type: AppStateConstants.ALL_PARTNERS_PAGE_COUNT,
    allPartnersPageCount: allPartnersPageCount,
  }
}

export const setNotAttemtedDailyCleaningJobPageNumber = (
  notAttemtedDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER,
    notAttemtedDailyCleaningJobPageNumber:
      notAttemtedDailyCleaningJobPageNumber,
  }
}

export const setCompletedDailyCleaningJobPageNumber = (
  completedDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER,
    completedDailyCleaningJobPageNumber: completedDailyCleaningJobPageNumber,
  }
}

export const setApprovalPendingDailyCleaningJobPageNumber = (
  approvalPendingDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER,
    approvalPendingDailyCleaningJobPageNumber:
      approvalPendingDailyCleaningJobPageNumber,
  }
}

export const setNotCleanDailyCleaningJobPageNumber = (
  notCleanDailyCleaningJobPageNumber
) => {
  return {
    type: AppStateConstants.NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER,
    notCleanDailyCleaningJobPageNumber: notCleanDailyCleaningJobPageNumber,
  }
}

export const setNoVehicleDailyCleaningPageNumber = (
  noVehicleDailyCleaningPageNumber
) => {
  return {
    type: AppStateConstants.NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER,
    noVehicleDailyCleaningPageNumber: noVehicleDailyCleaningPageNumber,
  }
}

export const setScheduledOnDemandJobPageNumber = (
  scheduledOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER,
    scheduledOnDemandJobPageNumber: scheduledOnDemandJobPageNumber,
  }
}

export const setStartedOnDemandJobPageNumber = (
  startedOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.STARTED_ON_DEMAND_JOB_PAGE_NUMBER,
    startedOnDemandJobPageNumber: startedOnDemandJobPageNumber,
  }
}

export const setCompletedOnDemandJobPageNumber = (
  completedOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER,
    completedOnDemandJobPageNumber: completedOnDemandJobPageNumber,
  }
}

export const setApprovalPendingOnDemandJobPageNumber = (
  approvalPendingOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER,
    approvalPendingOnDemandJobPageNumber: approvalPendingOnDemandJobPageNumber,
  }
}

export const setNotCleanOnDemandJobPageNumber = (
  notCleanOnDemandJobPageNumber
) => {
  return {
    type: AppStateConstants.NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER,
    notCleanOnDemandJobPageNumber: notCleanOnDemandJobPageNumber,
  }
}

export const setNoVehicleOnDemandPageNumber = (noVehicleOnDemandPageNumber) => {
  return {
    type: AppStateConstants.NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER,
    noVehicleOnDemandPageNumber: noVehicleOnDemandPageNumber,
  }
}

export const setLoggedInUserDetails = (logged_in_user_details) => {
  return {
    type: AppStateConstants.LOGGED_IN_USER_DETAILS,
    logged_in_user_details: logged_in_user_details,
  }
}

export const setPartnerAttendenceStartedPageNumber = (
  partnerAttendenceStartedPageNumber
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER,
    partnerAttendenceStartedPageNumber: partnerAttendenceStartedPageNumber,
  }
}

export const setPartnerAttendencePresentPageNumber = (
  partnerAttendencePresentPageNumber
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER,
    partnerAttendencePresentPageNumber: partnerAttendencePresentPageNumber,
  }
}

export const setPartnerAttendenceAbsentPageNumber = (
  partnerAttendenceAbsentPageNumber
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER,
    partnerAttendenceAbsentPageNumber: partnerAttendenceAbsentPageNumber,
  }
}

export const setPartnerAttendenceSelectedTab = (
  partnerAttendenceSelectedTab
) => {
  return {
    type: AppStateConstants.PARTNER_ATTENDENCE_SELECTED_TAB,
    partnerAttendenceSelectedTab: partnerAttendenceSelectedTab,
  }
}
export const setApartmentDetailsSelectedTab = (apartmentDetailsSelectedTab) => {
  return {
    type: AppStateConstants.APARTMENT_DETAILS_SELECTED_TAB,
    apartmentDetailsSelectedTab: apartmentDetailsSelectedTab,
  }
}
export const setDailyCleaningJobSelectedTab = (dailyCleaningJobSelectedTab) => {
  return {
    type: AppStateConstants.DAILY_CLEANING_JOB_SELECTED_TAB,
    dailyCleaningJobSelectedTab: dailyCleaningJobSelectedTab,
  }
}

export const setOnDemandJobSelectedTab = (onDemandJobSelectedTab) => {
  return {
    type: AppStateConstants.ON_DEMAND_JOB_SELECTED_TAB,
    onDemandJobSelectedTab: onDemandJobSelectedTab,
  }
}

export const setReportedDamagePageNumber = (reportedDamagePageNumber) => {
  return {
    type: AppStateConstants.REPORTED_DAMAGE_PAGE_NUMBER,
    reportedDamagePageNumber: reportedDamagePageNumber,
  }
}

export const setReportedJobsApprovedPageNumber = (
  reportedJobsApprovedPageNumber
) => {
  return {
    type: AppStateConstants.REPORTED_JOBS_APPROVED_PAGE_NUMBER,
    reportedJobsApprovedPageNumber: reportedJobsApprovedPageNumber,
  }
}

export const setReportedJobsNotApprovedPageNumber = (
  reportedJobsNotApprovedPageNumber
) => {
  return {
    type: AppStateConstants.REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER,
    reportedJobsNotApprovedPageNumber: reportedJobsNotApprovedPageNumber,
  }
}

export const setComplaintPageNumber = (complaintJobsPageNumber) => {
  return {
    type: AppStateConstants.COMPLAINT_JOBS_PAGE_NUMBER,
    complaintJobsPageNumber: complaintJobsPageNumber,
  }
}

export const setServicePlacesPageNumber = (servicePlacesPageNumber) => {
  return {
    type: AppStateConstants.SERVICE_PLACES_PAGE_NUMBER,
    servicePlacesPageNumber: servicePlacesPageNumber,
  }
}

export const setCarBrandsPageNumber = (carBrandsPageNumber) => {
  return {
    type: AppStateConstants.SET_CAR_BRANDS_PAGE_NUMBER,
    carBrandsPageNumber: carBrandsPageNumber,
  }
}

export const setCarModelsPageNumber = (carModelsPageNumber) => {
  return {
    type: AppStateConstants.SET_CAR_MODELS_PAGE_NUMBER,
    carModelsPageNumber: carModelsPageNumber,
  }
}
export const setServicePackagePageNumber = (servicePackagePageNumber) => {
  return {
    type: AppStateConstants.SERVICE_PACKAGE_PAGE_NUMBER,
    servicePackagePageNumber: servicePackagePageNumber,
  }
}

export const setBikeBrandsPageNumber = (bikeBrandsPageNumber) => {
  return {
    type: AppStateConstants.SET_BIKE_BRANDS_PAGE_NUMBER,
    bikeBrandsPageNumber: bikeBrandsPageNumber,
  }
}

export const setBikeModelsPageNumber = (bikeModelsPageNumber) => {
  return {
    type: AppStateConstants.SET_BIKE_MODELS_PAGE_NUMBER,
    bikeModelsPageNumber: bikeModelsPageNumber,
  }
}

export const setFilterAtNewDailyCleaningBookings = (
  filterAtNewDailyCleaningBookings
) => {
  return {
    type: AppStateConstants.FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS,
    filterAtNewDailyCleaningBookings: filterAtNewDailyCleaningBookings,
  }
}

export const setOnDemandNewBookingCount = (onDemandNewBookingCount) => {
  return {
    type: AppStateConstants.ON_DEMAND_NEW_BOOKING_COUNT,
    onDemandNewBookingCount: onDemandNewBookingCount,
  }
}
