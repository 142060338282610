import React, {useState, useEffect} from "react"
import HyperDatepicker from "../components/Datepicker"
import {Row, Col, Card, OverlayTrigger, Modal} from "react-bootstrap"
import {Link} from "react-router-dom"
import moment from "moment"
import swal from "sweetalert"
import callApi from "../utils/apiCaller"
import {FormInput} from "../components"

const OnDemandSchedule = (props) => {
  const data = props.data ? props.data : {}
  const [isModalVisible, setModal] = useState(false)
  const [reason, setReason] = useState(data.reason)
  const [signInModal, setSignInModal] = useState(true)
  const [scheduleDate, setScheduleDate] = React.useState(new Date())
  const [timeSlots, setTimeSlots] = React.useState([])
  const [scheduleTime, setScheduleTime] = React.useState(null)
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState(null)

  const getTimeSlots = () => {
    if (data.servicepackageId._id) {
      callApi(`servicebooking/gettimeslot`, "POST", {
        servicepackageId: data.servicepackageId._id,
      }).then((res) => {
        if (res.data) {
          setTimeSlots(res.data)
        }
      })
    }
  }

  useEffect(() => {
    getTimeSlots()
  }, [])

  const activateService = () => {
    // ask for confirmation with sweet alert
    swal({
      title: "Activate this booking?",
      // text: "You want to activate this booking",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const info = {
          servicebookingId: data._id,
          reason: reason,
        }
        console.log("info_____", info)
        callApi("servicebooking/reactivatesubscription", "POST", info).then(
          (res) => {
            if (res.success) {
              setModal(false)
              setReason("")
              swal("Success", "Booking Activated successfully", "success")
              props.getData(props.data._id)
            } else {
              setModal(false)
              swal("Error", res.message, "error")
              props.getData(props.data._id)
            }
          }
        )
      }
    })
  }

  const scheduleBooking = () => {
    console.log(
      "data.isScheduled",
      scheduleTime,
      moment(scheduleDate).format("YYYY-MM-DD")
    )
    if (data._id && scheduleDate && selectedTimeSlot) {
      const info = {
        servicebookingId: data._id,
      }
      if (data.isScheduled) {
        info.rescheduled_date = moment(scheduleDate).format("YYYY-MM-DD")
        info.rescheduled_timeSlot = data?.serviceType != "on-demand"?selectedTimeSlot:moment(selectedTimeSlot, 'HH:mm').diff(moment().startOf('day'),'minutes')
        info.serviceType = data?.serviceType;
      }
      if (!data.isScheduled) {
        info.service_start_date = moment(scheduleDate).format("YYYY-MM-DD")
        info.timeSlot = selectedTimeSlot
      }
      const url = data?.isScheduled
        ? "ondemand/reschedule"
        : "ondemand/toschedule"

      const name = data?.isScheduled ? "re shedule" : "schedule"

      swal({
        title: `Do you want to ${name} booking `,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          callApi(url, "POST", info).then((res) => {
            if (res.success) {
              console.log("res____ res", res)
              setSignInModal(false)
              props.getData(props.data._id)
              swal("Success", "Booking scheduled successfully", "success")
            } else {
              setSignInModal(false)
              swal("Error", res.message, "error")
            }
          })
        }
      })
    }
  }

  return (
    <Modal
      show={signInModal}
      onHide={() => {
        setSignInModal(!signInModal)
        props.onHide()
      }}
    >
      <Modal.Body>
        <div className="text-center mt-2 mb-4">
          <h4>Update Booking Status</h4>
          <div className="text-center">
            <p>Select Schedule Date</p>
            <HyperDatepicker
              hideAddon={true}
              value={scheduleDate}
              onChange={(date) => {
                setScheduleDate(date)
              }}
            />
            <br></br>
            {data?.serviceType != "on-demand" ?
                <FormInput
                  label="Select timeslot"
                  type="select"
                  name="partnerStatus"
                  placeholder="Partner Status"
                  key="partnerStatus"
                  value={scheduleTime}
                  onChange={(e) => {
                    setScheduleTime(e.target.value)
                    setSelectedTimeSlot(e.target.value)
                  }}
                >
                  <option>Select time slot</option>
                  {timeSlots.map((time, index) => (
                    <option key={index} value={time._id}>
                      {time.name}
                    </option>
                  ))}
                </FormInput>
                :
                <FormInput
                  type="time"
                  label="Select time"
                  name="time"
                  key="partnerStatus"
                  value={scheduleTime}
                  onChange={(e) => {
                    setScheduleTime(e.target.value)
                    setSelectedTimeSlot(e.target.value)
                  }}
                />
              }
            {/* <FormInput
              label="Partner Status"
              type="select"
              name="partnerStatus"
              placeholder="Partner Status"
              key="partnerStatus"
              value={scheduleTime}
              onChange={(e) => {
                setScheduleTime(e.target.value)
                setSelectedTimeSlot(e.target.value)
              }}
            >
              <option key={0} value={null}>
                Select Time Slot
              </option>
              {timeSlots.map((time, index) => (
                <option key={index} value={time._id}>
                  {time.name}
                </option>
              ))}
            </FormInput> */}
          </div>
          <div className="mb-3 text-center mt-4">
            <button
              onClick={() => {
                scheduleBooking()
              }}
              className="btn btn-rounded btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default OnDemandSchedule
