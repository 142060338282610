// @flow
import React, { useEffect, useReducer, useState } from "react";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import callApi from "../../utils/apiCaller";
import PackageAddOnDescription from "./components/PackageAddOnDescription";
import PackagePrices from "./components/PackagePrices";
import PackageApartmentsOnDemand from "./components/PackageApartmentsOnDemand";
import AgencyBoxAddOn from "./components/AgencyBoxAddOn";
import PackageTimeSlots from "./components/PackageTimeSlots";
const ServicePackageAddOnDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = React.useState(null);
  const [addonData, setAddonData] = React.useState(null);
  const [apartments, setApartments] = useState(null);

  useEffect(() => {
    const _id = searchParams.get("id");
    getData(_id);
    getApartments(_id);
    getAddons();
  }, []);

  const getData = (id) => {
    if (id) {
      callApi(`sp/daily/${id}`, "GET").then((res) => {
        if (res.success) {
          setData(res.data);
        }
      });
    }
  };

  const getApartments = (id) => {
    if (id) {
      callApi(`sp/ondemand/getlocation`, "POST", {
        servicepackageId: id,
      }).then((res) => {
        console.log("res__-------", res);
        if (res.success) {
          setApartments(res.data);
        }
      });
    }
  };

  const getAddons = () => {
    callApi(`sp/ondemand/list`, "GET").then((res) => {
      console.log("res`_______", res);
      if (res.success) {
        setAddonData(res.data);
      }
    });
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Packages", path: "/service-packages/all-packages" },
          {
            label: "Package details",
            active: true,
          },
        ]}
        title={"Add On Details"}
      />
      <Card>
        <Card.Body>
          {data && <AgencyBoxAddOn data={data} />}
          {data && <PackageAddOnDescription data={data} />}
          {data && <PackageTimeSlots data={data} />}
        </Card.Body>
      </Card>
    </>
  );
};

export default ServicePackageAddOnDetails;
