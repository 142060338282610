// @flow
import React, { useEffect, useState } from "react"
import { Row, Col, Modal, Button } from "react-bootstrap"
import Select from "react-select"
import callApi from "../../../utils/apiCaller"
import { FormInput } from "../../../components"
import _ from "lodash"
import { DateRangePicker } from 'react-date-range';
import moment from "moment"

const JobsFilterComponent = (props) => {
  const [cleanerDataFromApi, setCleanerDataFromApi] = React.useState([])
  const [selectedCleaner, setSelectedCleaner] = useState([])
  const [cities, setCity] = React.useState([])
  const [locationListByCity, setLocationListByCity] = React.useState([])
  const [location, setLocation] = React.useState([])
  const [selectedAgency, setSelectedAgency] = React.useState([])
  const [cityId, setCityId] = React.useState(null)
  const [apartments, setApartments] = React.useState([])
  const [apartmentsFromApi, setApartmentsFromApi] = React.useState([])
  const [vehicleType, setVehicleType] = useState("")
  const [timeSlotsFromApi, setTimeSlotsFromApi] = useState([])
  const [timeSlot, setTimeSlot] = useState([])
  const [blocksFromApi, setBlocksFromApi] = useState([])
  const [block, setBlock] = useState([])
  const [agencyDataFromApi, setAgencyDataFromApi] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState();

  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [datePickerFor, setDatePickerFor] = useState('');
  const [selectionRange, setSelectionRange] = useState({})
  const [serviceDateSelectionRange, setServiceDateSelectionRange] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({
    value: "all",
    label: "All",
  });
  const [statusList, setStatusList] = useState([
    {
      value: "all",
      label: "All",
    },
    {
      value: "active",
      label: "Active",
    },
    {
      value: "expired",
      label: "Expired",
    },
  ])

  const fetchAgencies = (num, search) => {
    const info = {
      pageNum: num,
    }
    if (search) {
      info.search = search
    }

    callApi("agency/getall", "POST", info).then((res) => {
      if (res.data) {
        setAgencyDataFromApi(res.data)
        const list = res?.data?.map((item) => {
          let role = item.role === "AGENCY_OWNER" ? "Agency" : "Independent"
          return {
            value: `${item.name} - ${role}`,
            label: `${item.name} - ${role}`,
            _id: item._id,
            ownerId: item.ownerId,
          }
        })
        setAgencyDataFromApi(list)
      }
    })
  }

  useEffect(() => {
    // getAllStaff()
    getCities()
    fetchAgencies(1)
    getAllApartments()
    getTimeSlots()
    getServiceTypes();
  }, [])

  const getServiceTypes = (id) => {
    callApi(`sp/ondemand/allservicetype`, "GET").then((res) => {
      if (res.success) {
        const list = res?.data?.map((item) => {
          return {
            value: item?.categoryName,
            label: item?.categoryName,
            _id: item?._id,
          }
        })
        setServiceTypes(list)
      }
    })
  }

  const getCities = async () => {
    callApi(`city`, `get`).then((res) => {
      if (res.success) {
        setCity(res.data)
      }
    })
  }

  const getAllStaff = async (num, search) => {
    const info = {
      pageNum: num,
    }
    if (search) {
      info.search = search
    }

    callApi("partner/globalsearch", "POST", info).then((res) => {
      if (res.data) {
        const list = res?.data?.map((item) => {
          return {
            value: `${item.name} - ${item.phone}`,
            label: `${item.name} - ${item.phone}`,
            _id: item._id,
          }
        })
        setCleanerDataFromApi(list)
      }
    })
  }
  const getStaffByAgency = async (value) => {
    const data = {}
    if (value?._id) {
      data.agencyId = value.ownerId
    }
    callApi("partner/getStaff", "POST", data).then((res) => {
      if (res.success) {
        const list_b = res.data.map((partners) => {
          return {
            value: `${partners.name} - ${partners.phone}`,
            label: `${partners.name} - ${partners.phone}`,
            _id: partners._id,
          }
        })
        setCleanerDataFromApi(list_b)
      }
    })
  }
  const getApartments = async (value) => {
    const location = value.map((i) => {
      return i._id
    })

    callApi(`apartment`, `POST`, { locationId: location }).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setApartmentsFromApi(list_b)
      }
    })
  }
  const getAllApartments = async (value) => {
    const data = {}
    if (value) {
      data.search = value
    }
    callApi(`apartments/getall`, `POST`, data).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setApartmentsFromApi(list_b)
      }
    })
  }

  const getLocationListByCity = async (value) => {
    callApi(`location/${value}`, `get`).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setLocationListByCity(list_b)
      }
    })
  }

  const getBlocks = async (value) => {
    callApi(`blocks/getall`, `POST`, {
      apartmentId: value,
    }).then((res) => {
      if (res.success) {
        const list_b = res.data.map((location) => {
          return {
            label: location.name,
            value: location.name,
            _id: location._id,
          }
        })
        setBlocksFromApi(list_b)
      }
    })
  }

  const getTimeSlots = async () => {
    callApi(`dailytimeslot`, `get`).then((res) => {
      if (res.success) {
        setTimeSlotsFromApi(res.data)
      }
    })
  }

  const handlePartnerChange = (inputValue) => {
    fetchAgencies(1, inputValue)
  }

  const applyFilters = (e) => {
    let filterObject = {}
    if (cityId) {
      filterObject.cityId = [cityId]
    }
    if (!_.isEmpty(location)) {
      filterObject.locationId = location.map((i) => {
        return i._id
      })
    }
    if (!_.isEmpty(apartments)) {
      filterObject.servicePlaceNameId = apartments.map((i) => {
        return i._id
      })
    }
    if (!_.isEmpty(vehicleType)) {
      filterObject.vehicle_type = vehicleType
    }
    if (!_.isEmpty(timeSlot)) {
      filterObject.timeSlot = [timeSlot]
    }
    if (!_.isEmpty(block)) {
      filterObject.block_id = block.map((i) => {
        return i._id
      })
    }
    console.log("selectedCleaner", selectedCleaner)
    if (!_.isEmpty(selectedCleaner?._id)) {
      filterObject.cleanerId = selectedCleaner?._id
    }
    console.log("selectedAgency", selectedAgency)
    if (!_.isEmpty(selectedAgency)) {
      filterObject.agencyId = selectedAgency._id
    }
    if (!_.isEmpty(selectionRange)) {
      filterObject.service_booking_date_start = moment(selectionRange.startDate).format("YYYY-MM-DD");
      filterObject.service_booking_date_end = moment(selectionRange.endDate).format("YYYY-MM-DD");
    }
    if (!_.isEmpty(serviceDateSelectionRange)) {
      filterObject.service_start_date = moment(serviceDateSelectionRange.startDate).format("YYYY-MM-DD");
      filterObject.service_end_date = moment(serviceDateSelectionRange.endDate).format("YYYY-MM-DD");
    }
    if (!_.isEmpty(selectedServiceType)) {
      filterObject.serviceTypeId = [selectedServiceType?._id]
    }
    if (!_.isEmpty(selectedStatus)) {
      if (selectedStatus.value == "active") {
        filterObject.isActive = true;
        filterObject.isExpired = false;
      }
      if (selectedStatus.value == "expired") {
        filterObject.isExpired = true;
        filterObject.isActive = false;
      }
    }
    props.applyAllFilters(filterObject)
  }

  const handleApartmentChange = (inputValue) => {
    getAllApartments(inputValue)
  }

  const handleSelect = (ranges, type) => {
    if (type == "booking") {
      setSelectionRange(ranges[Object.keys(ranges)[0]]);
    }
    else {
      setServiceDateSelectionRange(ranges[Object.keys(ranges)[0]]);
    }
  }

  return (
    <>
      <>
        <Col md={6} className="mb-2 mt-2">
          {!props.showPartner && (
            <>
              <p className="fw-bold ">Search agency or independent partner</p>
              <Select
                isClearable
                options={agencyDataFromApi}
                className="react-select"
                classNamePrefix="react-select"
                value={selectedAgency}
                onChange={(e) => {
                  setSelectedAgency(e)
                  setSelectedCleaner([])
                  getStaffByAgency(e)
                }}
                onInputChange={(text) => fetchAgencies(1, text)}
                placeholder="Select or search Agency"
              ></Select>
            </>
          )}
        </Col>
        {!_.isEmpty(cleanerDataFromApi) && (
          <Col md={6} className="mb-2 mt-2">
            <>
              <p className="fw-bold ">Select or search partner</p>
              <Select
                isClearable
                options={cleanerDataFromApi}
                className="react-select"
                classNamePrefix="react-select"
                value={selectedCleaner}
                onChange={(e) => {
                  setSelectedCleaner(e)
                }}
                onInputChange={handlePartnerChange}
                placeholder="Select or search cleaner"
              ></Select>
            </>
          </Col>
        )}
        <Row>
          <Col md={4} className="mb-2 mt-1">
            <FormInput
              style={{
                marginTop: 2,
              }}
              labelClassName="fw-bold"
              label="City"
              type="select"
              name="city"
              placeholder="Enter password"
              key="city"
              defaultValue={"select city"}
              onChange={(e) => {
                getLocationListByCity(e.target.value)
                setCityId(e.target.value)
                setLocation([])
              }}
            >
              <option key="City" value="City">
                --select city--
              </option>
              {cities.map((city) => (
                <option key={city.id} value={city._id} _id={city._id}>
                  {city.name}
                </option>
              ))}
            </FormInput>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Location</p>
            <Select
              isMulti={true}
              options={locationListByCity}
              className="react-select"
              classNamePrefix="react-select"
              value={location}
              onChange={(e) => {
                setLocation(e)
                setApartments([])
                getApartments(e)
              }}
              placeholder="Select Location"
            ></Select>
          </Col>
          <Col md={4} className="mb-2">
            <p className="fw-bold">Apartments</p>
            <Select
              isMulti={true}
              options={apartmentsFromApi}
              className="react-select"
              classNamePrefix="react-select"
              value={apartments}
              onChange={(e) => {
                if (!_.isEmpty(e[0]?._id)) {
                  getBlocks(e[0]._id)
                }
                setApartments(e)
              }}
              placeholder="Select Apartments"
              onInputChange={handleApartmentChange}
            ></Select>
          </Col>
          <Col md={4} className="mb-2 mt-1">
            <FormInput
              style={{
                marginTop: 2,
              }}
              isClearable
              labelClassName="fw-bold"
              label="Vehicle type"
              type="select"
              name="vehicle_type"
              placeholder="Select vehicle"
              key="vehicle_type"
              value={vehicleType}
              defaultValue={"vehicle type"}
              onChange={(e) => {
                setVehicleType(e.target.value)
              }}
            >
              <option key="" value="">
                --Select vehicle type --
              </option>
              <option key="car" value="car">
                Car
              </option>
              <option key="bike" value="bike">
                Bike
              </option>
            </FormInput>
          </Col>
          {props?.formOnDemand ?
            <>
              <Col md={4} className="mb-2 mt-1">
                <p className="fw-bold ">Booking Date</p>
                <Row>
                  <Col md={12}>
                    <input type={'text'} className="form-control" placeholder="--select date range--" onClick={() => {
                      if (_.isEmpty(selectionRange)) {
                        setSelectionRange({
                          startDate: new Date(moment()),
                          endDate: new Date(moment())
                        })
                      }
                      setDatePickerFor('booking');
                      setShowDateRangeModal(true);
                    }} value={!_.isEmpty(selectionRange) ? `${moment(selectionRange?.startDate).format("DD MMM, YYYY")} to ${moment(selectionRange?.endDate).format("DD MMM, YYYY")}` : ""} />
                  </Col>
                  <Col md={1}>
                    {!_.isEmpty(selectionRange) ?
                      <div style={{
                        position: 'relative',
                        right: 12,
                        top: 9,

                      }}
                      onClick={()=>{
                        setSelectionRange({})
                      }}
                      >
                        <i className="mdi mr-1  dripicons-cross" style={{
                          padding: 4,
                          backgroundColor: '#80808050',
                          borderRadius: '5px'
                        }}></i>
                      </div>
                      :
                      null
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mb-2 mt-1">
                <p className="fw-bold ">Service Date</p>
                <Row>
                  <Col md={12}>
                    <input type={'text'} className="form-control" placeholder="--select date range--" onClick={() => {
                      if (_.isEmpty(serviceDateSelectionRange)) {
                        setServiceDateSelectionRange({
                          startDate: new Date(moment()),
                          endDate: new Date(moment())
                        })
                      }
                      setDatePickerFor('service');
                      setShowDateRangeModal(true);
                    }} value={!_.isEmpty(serviceDateSelectionRange) ? `${moment(serviceDateSelectionRange?.startDate).format("DD MMM, YYYY")} to ${moment(serviceDateSelectionRange?.endDate).format("DD MMM, YYYY")}` : ""} />
                  </Col>
                  <Col md={1}>
                    {!_.isEmpty(serviceDateSelectionRange) ?
                      <div style={{
                        position: 'relative',
                        right: 12,
                        top: 9,

                      }}
                      onClick={()=>{
                        setServiceDateSelectionRange({})
                      }}
                      >
                        <i className="mdi mr-1  dripicons-cross" style={{
                          padding: 4,
                          backgroundColor: '#80808050',
                          borderRadius: '5px'
                        }}></i>
                      </div>
                      :
                      null
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="mb-2 mt-1">
                <p className="fw-bold ">Services</p>
                <Select
                  placeholder="Select or search service"
                  isClearable
                  options={serviceTypes}
                  className="react-select"
                  classNamePrefix="react-select"
                  value={selectedServiceType}
                  onChange={(e) => {
                    setSelectedServiceType(e)
                  }}
                ></Select>
              </Col>
              {props?.showStatusFilter ?
                <Col md={4} className="mb-2 mt-1">
                  <p className="fw-bold ">Status</p>
                  <Select
                    isClearable
                    options={statusList}
                    className="react-select"
                    classNamePrefix="react-select"
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e)
                    }}
                    placeholder="Select Status"
                  ></Select>
                </Col>
                :
                null
              }
            </>
            : null
          }

        </Row>
      </>
      <div className="text-end mt-2">
        <button
          className="btn btn-bloc "
          style={{ backgroundColor: "#2A882A", color: "white" }}
          onClick={() => applyFilters()}
        >
          <i className="mdi dripicons-checkmark mr-1"></i> Apply selected
          filters
        </button>
      </div>
      <Modal show={showDateRangeModal} onHide={() => { setShowDateRangeModal(false) }} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Date Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <DateRangePicker
              ranges={datePickerFor == "booking" ? [selectionRange] : [serviceDateSelectionRange]}
              onChange={(ranges) => handleSelect(ranges, datePickerFor)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              setShowDateRangeModal(false)
            }}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default JobsFilterComponent
