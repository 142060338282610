// @flow
import React, {useRef, useEffect, forwardRef} from "react"
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table"
import classNames from "classnames"

// components
import Pagination from "./Pagination"

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchBoxClass,
}) => {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className={classNames(searchBoxClass)}>
      <span className="d-flex align-items-center">
        Search :{" "}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          placeholder={`${count} records...`}
          className="form-control w-auto ms-1"
        />
      </span>
    </div>
  )
}

const IndeterminateCheckbox = forwardRef(({indeterminate, ...rest}, ref) => {
  const defaultRef = useRef()
  const resolvedRef: any = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          {...rest}
        />
        <label htmlFor="form-check-input" className="form-check-label"></label>
      </div>
    </>
  )
})

type TableProps = {
  isSearchable?: boolean,
  isSortable?: boolean,
  pagination?: boolean,
  isSelectable?: boolean,
  isExpandable?: boolean,
  pageSize: number,
  columns: Array<any>,
  data: Array<any>,
  searchBoxClass?: string,
  tableClass?: string,
  theadClass?: string,
  sizePerPageList: {
    text: string,
    value: number,
  }[],
}

const NewTable = (props: TableProps): React$Element<React$FragmentType> => {
  const isSearchable = props["isSearchable"] || false
  const isSortable = props["isSortable"] || false
  const pagination = props["pagination"] || false
  const isSelectable = props["isSelectable"] || false
  const isExpandable = props["isExpandable"] || false
  const selected = []

  const dataTable = useTable(
    {
      columns: props["columns"],
      data: props["data"],
      initialState: {pageSize: props["pageSize"] || 10},
    },
    isSearchable && useGlobalFilter,
    isSortable && useSortBy,
    isExpandable && useExpanded,
    pagination && usePagination,
    isSelectable && useRowSelect,
    (hooks) => {
      isSelectable &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({getToggleAllPageRowsSelectedProps}) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({row}) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ])

      isExpandable &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            // Build our expander column
            id: "expander", // Make sure it has an ID
            Header: ({getToggleAllRowsExpandedProps, isAllRowsExpanded}) => (
              <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? "-" : "+"}
              </span>
            ),
            Cell: ({row}) =>
              // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
              // to build the toggle for expanding a row
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      // We can even use the row.depth property
                      // and paddingLeft to indicate the depth
                      // of the row
                      paddingLeft: `${row.depth * 2}rem`,
                    },
                  })}
                >
                  {row.isExpanded ? "-" : "+"}
                </span>
              ) : null,
          },
          ...columns,
        ])
    }
  )

  let rows = dataTable.rows

  return (
    <>
      {isSearchable && (
        <GlobalFilter
          preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
          globalFilter={dataTable.state.globalFilter}
          setGlobalFilter={dataTable.setGlobalFilter}
          searchBoxClass={props["searchBoxClass"]}
        />
      )}

      <div className="table-responsive">
        <table
          {...dataTable.getTableProps()}
          className={classNames(
            "table table-centered react-table",
            props["tableClass"]
          )}
        >
          <thead className={props["theadClass"]}>
            {dataTable.headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(
                      column.sort && column.getSortByToggleProps()
                    )}
                    className={classNames({
                      sorting_desc: column.isSortedDesc === true,
                      sorting_asc: column.isSortedDesc === false,
                      sortable: column.sort === true,
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...dataTable.getTableBodyProps()}>
            {(rows || []).map((row, i) => {
              dataTable.prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  // onClick={() => row.toggleRowSelected()}
                  // onClick={() => props["tableSelected"](rows)}
                >
                  {row.cells.map((cell) => {
                    // console.log("cell", cell)
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => props["onClick"](row.original)}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {pagination && (
        <Pagination
          tableProps={dataTable}
          sizePerPageList={props["sizePerPageList"]}
        />
      )}
    </>
  )
}

export default NewTable
