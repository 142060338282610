import fetch from "isomorphic-fetch"
const PROTOCOL = window.location.protocol
//const HOST = 'localhost:8000';
// const API_URL = `https://test-api.honc.io`
// const API_URL = `https://devapi.honc.io`;
// const API_URL = "http://192.168.0.123:5200"
// const API_URL = "https://devdashboardapi.honc.io"
const API_URL = "https://uatadminapi.honc.io"
// const API_URL = "https://adminapi.honc.io"
// const API_URL = "https://betaadminapi.honc.io"

export const IMAGE_UPLOAD_URL = "https://devapi.honc.io/s3/upload-compressed"
// export const IMAGE_UPLOAD_URL = "https://api.honc.io/s3/upload-compressed"

export default function callApi(endpoint, method = "get", body) {
  let localStorage = window.localStorage
  let user = localStorage.getItem("LOGGED_IN_USER_DETAILS")
    ? JSON.parse(localStorage.getItem("LOGGED_IN_USER_DETAILS"))
    : {}
  let headers = {}
  headers["content-type"] = "application/json"
  if (user && user !== "") {
    headers.Authorization = user.token
  }
  console.log(`${API_URL}/${endpoint}`)
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  })
    .then((response) => response.json().then((json) => ({json, response})))
    .then(({json, response}) => {
      if (!response.ok) {
        return Promise.reject(json)
      }
      return json
    })
    .then(
      (response) => response,
      (error) => error
    )
}
