import React from "react"
import {Row, Col, Card, OverlayTrigger, Tooltip} from "react-bootstrap"
import {Link} from "react-router-dom"
import callApi from "../../../utils/apiCaller"
import swal from "sweetalert"

const AddressDetailsInBookings = (props) => {
  const [data, setData] = React.useState(props.data.addressId || {})
  const [isEditMode, setIsEditMode] = React.useState(false)

  const handleUpdate = () => {
    if (props.data._id) {
      const info = {
        servicebookingId: props.data._id,
        parking_slot: data.parking_slot,
        pillar_no: data.pillar_no,
      }
      console.log("info", info)
      callApi("servicebooking/updatebooking", "POST", info).then((res) => {
        if (res.success) {
          // update swal success
          swal({
            title: "Success",
            text: "Address updated successfully",
            icon: "success",
            button: "Ok",
          })
            .then(() => {
              setIsEditMode(false)
              props.getData(props.data._id)
            })
            .catch(swal.noop)
        } else {
          // update swal error
          swal({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "Ok",
          })
            .then(() => {
              setIsEditMode(false)
              props.getData(props.data._id)
            })
            .catch(swal.noop)
        }
      })
    }
  }

  return (
    <Card>
      <Card.Body>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <h4 className="header-title mt-2">Address details</h4>
          {!isEditMode && !props?.data?.isCancelled && (
            <div
              style={{cursor: "pointer", color: "#00a8ff"}}
              className="action-icon"
              onClick={() => setIsEditMode(true)}
            >
              <i className="mdi mdi-square-edit-outline action-icon mb-3 btn btn-success btn-block">
                Edit
              </i>
            </div>
          )}
        </div>
        <div className="table-responsive">
          <table className="table mb-0">
            <thead className="table-light">
              <tr>
                <th>Title</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Address Name</td>
                <td>{data?.address_name}</td>
              </tr>
              <tr>
                <td>Address Type</td>
                <td>{data?.address_type}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{data?.location_id?.city}</td>
              </tr>
              <tr>
                <td>Location</td>
                <td>{data?.location_id?.name}</td>
              </tr>
              <tr>
                <td>Community</td>
                <td>{data?.community_id?.name}</td>
              </tr>
              <tr>
                <td>Flat no</td>
                <td>{data?.flat_no}</td>
              </tr>
              <tr>
                <td>Parking Slot</td>
                <td>
                  {isEditMode ? (
                    <input
                      type="text"
                      className="form-control"
                      value={data.parking_slot}
                      onChange={(e) => {
                        setData({
                          ...data,
                          parking_slot: e.target.value,
                        })
                      }}
                    />
                  ) : (
                    data?.parking_slot
                  )}
                </td>
              </tr>
              <tr>
                <td>Pillar</td>
                <td>
                  {isEditMode ? (
                    <input
                      type="text"
                      className="form-control"
                      value={data.pillar_no}
                      onChange={(e) => {
                        setData({
                          ...data,
                          pillar_no: e.target.value,
                        })
                      }}
                    />
                  ) : (
                    data?.pillar_no
                  )}
                </td>
              </tr>
              <tr>
                <td>Block</td>
                <td>{data?.block_id?.name}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {isEditMode && (
          <div className="text-end">
            <button
              className="btn btn-danger mt-2"
              style={{marginRight: "20px"}}
              onClick={() => setIsEditMode(false)}
            >
              <i className="mdi mdi-content-save"></i>Cancel
            </button>
            <button
              className="btn btn-success mt-2 pl-2 "
              onClick={() => handleUpdate()}
            >
              <i className="mdi mdi-content-save"></i> Save
            </button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default AddressDetailsInBookings
