// @flow
import React from "react"

import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import {Row, Col} from "react-bootstrap"
import moment from "moment"
import _ from "lodash"
import {useNavigate} from "react-router-dom"

const HoncCalendar = (props) => {
  const calendarStatus = [
    {
      id: 0,
      color: "#2a882a",
      status: "Car cleaned",
    },
    {
      id: 1,
      color: "#d32f2f",
      status: "Not cleaned",
    },
    {
      id: 2,
      color: "#6d6d6d",
      status: "Weekly Off",
    },
    {
      id: 3,
      color: "#0181e3",
      status: "No vehicle",
    },
    {
      id: 4,
      color: "#ffb100",
      status: "Is On Hold",
    },
    {
      id: 4,
      color: "#6300b3",
      status: "Not Attempted",
    },
  ]

  let navigate = useNavigate()
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div>
          <Calendar
            value={new Date()}
            onClickDay={(day) => {
              let foundDateData = _.find(props?.calenderData, {
                formatedDate: moment(day).format("YYYY-MM-DD"),
              })
              navigate(`/jobs/job-details?id=${foundDateData.taskId}`)
            }}
            tileClassName={({activeStartDate, date, view}) => {
              let foundDateData = _.find(props?.calenderData, {
                formatedDate: moment(date).format("YYYY-MM-DD"),
              })
              if (foundDateData?.status == "Completed") {
                return "calenderJobCompleted"
              }
              if (foundDateData?.status == "Cleaner did not clean") {
                return "calenderJobNotCompleted"
              }
              if (foundDateData?.status == "Not Attempted") {
                return "calenderJobNotAttempted"
              }
              if (foundDateData?.status == "Vehicle not available") {
                return "calenderJobNoVehicle"
              }
              if (foundDateData?.status == "HOLD") {
                return "calenderJobOnHold"
              }
              if (foundDateData?.status == "Week-off") {
                return "calenderWeekOff"
              }
            }}
            onActiveStartDateChange={({activeStartDate}) => {
              return props.getCalenderData(
                moment(activeStartDate).format("YYYY-MM-DD")
              )
            }}
          />
        </div>

        <div style={{marginLeft: "10px"}}>
          {calendarStatus.map((i) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "center",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: i.color,
                      marginTop: "6px",
                      height: "10px",
                      width: "20px",
                      marginRight: "5px",
                      borderRadius: "2px",
                    }}
                  ></div>
                  <div>
                    <p style={{}}>{i.status}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default HoncCalendar
