// @flow
import React, {useState, useContext} from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  Spinner,
  InputGroup,
  Form,
  Tab,
  Nav,
} from "react-bootstrap"
import moment from "moment"
import swal from "sweetalert"
import PageTitle from "../../../components/PageTitle"
import ReactPaginate from "react-paginate"
import callApi from "../../../utils/apiCaller"
import FilterComponent from "../components/FilterComponent"
import Table from "../../../components/Table"
import {Link, useSearchParams, useNavigate} from "react-router-dom"
import BookingDetails from "../BookingDetails"

const NotScheduledOnDemandBookings2 = ({history}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [pageCount, setpageCount] = useState(0)
  const [limit, setLimit] = useState(50)
  const [showFilter, setShowFilter] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [filters, setFilters] = useState(null)
  const [searchInput, setSearchInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [bookingsList, setBookingList] = React.useState(null)
  const [showFullDetails, setShowFullDetails] = React.useState(false)
  const [showCalender, setShowCalender] = React.useState(false)
  const [calenderData, setCalenderData] = React.useState([])
  const [showBookingDetails, setShowBookingDetails] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [showDetails, setShowDetails] = useState(false)
  const [bookingId, setBookingId] = useState(null)

  const fetchData = (num, filterType) => {
    let data = {
      pageNum: num,
      pageSize: limit,
    }

    if (filterType) {
      data = {
        ...data,
        ...filterType,
      }
    }
    if (filterType?.search) {
      data.search = filterType.search.trim()
    }
    setIsLoading(true)
    callApi("ondemand/unassigned", "POST", data).then((res) => {
      if (res.data) {
        setBookingList(res.data)
        setpageCount(res.totalPages || 1)
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }

  React.useEffect(() => {
    const _id = searchParams.get("id")
    if (_id) {
      setShowDetails(true)
      setBookingId(_id)
    }

    filters ? fetchData(pageNumber, filters) : fetchData(pageNumber)

    function backPressed(event) {
      if (showDetails) {
        setShowDetails(false)
        setSearchParams()
      } else {
        navigate(-1)
      }
    }

    window.history.pushState(null, null, document.URL)
    window.addEventListener("popstate", backPressed)
    return () => {
      window.removeEventListener("popstate", backPressed)
    }
  }, [loading, showDetails])

  const handlePageClick = async (data) => {
    setBookingList([])
    let locCurrentPage = data.selected
    locCurrentPage = locCurrentPage + 1
    setPageNumber(locCurrentPage)
    isFilterApplied
      ? fetchData(locCurrentPage, filters)
      : fetchData(locCurrentPage)
  }

  const optionHandler = (e) => {
    setLimit(e.target.value)
  }

  const removeFilters = () => {
    setShowFilter(false)
    fetchData(1)
  }
  const NameColumn = ({row}) => {
    return (
      <Link
        to="#"
        onClick={(event) => {
          event.preventDefault()
          setSearchParams(`?id=${row.original._id}`)
          setShowDetails(true)
          setBookingId(row.original._id)
        }}
      >
        <div className="table-user" style={{}}>
          {row?.original?.customerId?.name}
        </div>
      </Link>
    )
  }

  const DateCol = ({row}) => {
    return (
      <Link
        to="#"
        onClick={(event) => {
          event.preventDefault()
          setSearchParams(`?id=${row.original._id}`)
          setShowDetails(true)
          setBookingId(row.original._id)
        }}
      >
        <span>
          {moment(row?.original?.service_booking_date).format("DD MMM, YYYY")}
        </span>
      </Link>
    )
  }

  const serviceDate = ({row}) => {
    return row?.original?.expiry_date &&
      !moment(row?.original?.expiry_date).isBefore(moment(), "day") ? (
      <Link
        to="#"
        onClick={(event) => {
          event.preventDefault()
          setSearchParams(`?id=${row.original._id}`)
          setShowDetails(true)
          setBookingId(row.original._id)
        }}
      >
        <span>{moment(row?.original?.expiry_date).format("DD MMM, YYYY")}</span>
      </Link>
    ) : (
      <span className=" badge badge-info-lighten">
        expired on {moment(row?.original?.expiry_date).format("DD MMM, YYYY")}
      </span>
    )
  }

  const onDemandCol = [
    {
      Header: "Booking date",
      Cell: DateCol,
      sort: true,
    },
    {
      Header: "Expiry date",
      Cell: serviceDate,
      sort: true,
    },
    {
      Header: "Name",
      Cell: NameColumn,
      sort: true,
    },
    {
      Header: "Service Name",
      accessor: "servicepackageId.service_name",
    },
    {
      Header: "Vehicle type",
      accessor: "vehicle_type",
      sort: true,
    },
    {
      Header: "City",
      accessor: "city.name",
      sort: true,
    },
    {
      Header: "Locality",
      accessor: "locations.name",
      sort: true,
    },
    {
      Header: "Service place name",
      accessor: "apartments.name",
      sort: true,
    },
    {
      Header: "Service place type",
      accessor: "apartments.type",
      sort: true,
    },
    {
      Header: "Phone",
      accessor: "customerId.phone",
      sort: true,
    },
    {
      Header: "Vehicle number",
      accessor: "vehicle.license_number",
      sort: true,
    },
  ]

  let newColumns = [...onDemandCol]
  newColumns.unshift({
    Header: "SNo",
    accessor: "serialNo",
    Cell: ({row}) => {
      return <span>{limit * (pageNumber - 1) + row.index + 1}</span>
    },
  })

  const applyAllFilters = (filterObject) => {
    setIsFilterApplied(true)
    setFilters(filterObject)
    fetchData(1, filterObject)
  }

  const getData = (id) => {
    if (id) {
      callApi(`servicebooking/${id}`, "GET").then((res) => {
        if (res.success) {
          console.log("res______", res)
          let all_data = res.data
          all_data.info = res.info
          setData(all_data)
          setShowBookingDetails(true)
        }
      })
    }
  }

  const getCalenderData = (date, id) => {
    console.log("date____", date)
    let data = {
      servicebookingId: id,
      date: date,
    }
    callApi(`servicebooking/getcalaender`, "POST", data)
      .then((res) => {
        if (res.success) {
          let data = res?.data
          data = data.map((item) => {
            item.formatedDate = moment(item.date).format("YYYY-MM-DD")
            return item
          })
          setCalenderData(data)
        } else {
          swal("Alert", res.message, "alert")
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCalenderView = (status) => {
    setShowCalender(status)
  }

  if (showDetails) {
    return <>{bookingId && <BookingDetails id={bookingId} />}</>
  } else {
    return (
      <>
        <div style={{marginLeft: 26}}>
          <PageTitle
            breadCrumbItems={[
              {
                label: "Assigned Bookings",
                path: "/apps/ecommerce/customers",
                active: true,
              },
            ]}
            title={"Not Scheduled On-Demand Bookings"}
          />
        </div>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Col className="mb-3">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="d-flex align-items-center">
                      <InputGroup
                        style={{
                          display: "flex",
                          border: "1px solid #00000050",
                          borderRadius: 4,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Form.Control
                          placeholder="Search...."
                          aria-label="Search...."
                          aria-describedby="basic-addon2"
                          style={{border: 0}}
                          onChange={(e) => {
                            setSearchInput(e.target.value)
                          }}
                          id="search"
                          value={searchInput}
                          onKeyDown={(event) =>
                            event.key === "Enter" &&
                            fetchData(1, {search: searchInput})
                          }
                        ></Form.Control>
                        <div
                          style={{
                            cursor: "pointer",
                            marginRight: 10,
                            paddingLeft: 10,
                          }}
                          onClick={() => {
                            setSearchInput("")
                            setLoading(!loading)
                            fetchData(1, {search: searchInput})
                          }}
                        >
                          <i className="mdi mr-1  dripicons-cross"></i>
                        </div>
                        <button
                          className="btn btn-block"
                          style={{backgroundColor: "#2A882A", color: "white"}}
                          onClick={() => fetchData(1, {search: searchInput})}
                        >
                          <i className="mdi mr-1"></i> Search
                        </button>
                      </InputGroup>
                    </span>
                    {showFilter ? (
                      <Alert
                        variant="warning"
                        onClose={() => removeFilters()}
                        dismissible
                        className=""
                      >
                        Remove Filter
                      </Alert>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={() => setShowFilter(true)}
                      >
                        Add Filter
                      </button>
                    )}
                  </div>
                  {showFilter && (
                    <div>
                      <FilterComponent applyAllFilters={applyAllFilters} />
                    </div>
                  )}
                </Col>

                {isLoading ? (
                  <>
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="d-block mx-auto"
                      size="lg"
                    />
                  </>
                ) : (
                  <>
                    {bookingsList && (
                      <>
                        <Table
                          columns={newColumns}
                          data={bookingsList || []}
                          pageSize={limit}
                          sizePerPageList={[{text: limit, value: limit}]}
                          isSortable
                          theadClass="table-light"
                          searchBoxClass="mt-2 mb-3"
                          // onClick={(row) => {
                          //   setShowBookingDetails(true)
                          //   setBookingId(row._id)
                          //   getData(row._id)
                          //   getCalenderData(
                          //     moment().format("YYYY-MM-DD"),
                          //     row._id
                          //   )
                          // }}
                          onClick={(row) => {
                            // navigate({
                            //   pathname: "/bookings/booking-details",
                            //   search: `?id=${row._id}`,
                            // })
                            window.open(
                              `/bookings/booking-details?id=${row._id}`,
                              "_blank"
                            )
                          }}
                        />
                      </>
                    )}
                    <Row>
                      <Col lg={12} className="mt-3">
                        <Row>
                          <Col lg={6}>
                            <div className="d-lg-flex align-items-center text-center pb-1">
                              <label className="me-1">Display :</label>
                              <select
                                onChange={(e) => {
                                  optionHandler(e)
                                }}
                                className="form-select d-inline-block w-auto"
                              >
                                <option value={limit}>{limit}</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <ReactPaginate
                              previousLabel="previous"
                              nextLabel="next"
                              breakLabel="..."
                              pageCount={pageCount}
                              marginPagesDisplayed={2}
                              onPageChange={(bookingsList) => {
                                handlePageClick(bookingsList)
                              }}
                              initialPage={pageNumber - 1}
                              disableInitialCallback
                              containerClassName="pagination justify-content-center"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLinkClassName="page-link"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakClassName="page-item"
                              breakLinkClassName="page-link"
                              activeClassName="active"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default NotScheduledOnDemandBookings2
