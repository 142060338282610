import {AppStateConstants} from "./appConstants"

const user_details = localStorage.getItem(
  AppStateConstants.LOGGED_IN_USER_DETAILS
)

const initialState = {
  closedTicketCount: 1,
  openTicketPageCount: 1,
  holdTicketPageCount: 1,
  onDemandNewBookingPageCount: 1,
  onDemandNotAssignedBookingPageCount: 1,
  onDemandAssignedBookingPageCount: 1,
  onDemandUnAssignedBookingPageCount: 1,
  onDemandInactiveBookingPageCount: 1,
  allUsersPageCount: 1,
  allPartnersPageCount: 1,
  onDemandCanceledBookingPageCount: 1,
  dailyCleaningAssignedBookingPageCount: 1,
  dailyCleaningNewBookingPageCount: 1,
  dailyCleaningInactiveBookingPageCount: 1,
  notAttemtedDailyCleaningJobPageNumber: 1,
  completedDailyCleaningJobPageNumber: 1,
  approvalPendingDailyCleaningJobPageNumber: 1,
  notCleanDailyCleaningJobPageNumber: 1,
  noVehicleDailyCleaningPageNumber: 1,
  scheduledOnDemandJobPageNumber: 1,
  startedOnDemandJobPageNumber: 1,
  completedOnDemandJobPageNumber: 1,
  approvalPendingOnDemandJobPageNumber: 1,
  notCleanOnDemandJobPageNumber: 1,
  noVehicleOnDemandPageNumber: 1,
  logged_in_user_details: user_details,
  partnerAttendenceStartedPageNumber: 1,
  partnerAttendencePresentPageNumber: 1,
  partnerAttendenceAbsentPageNumber: 1,
  reportedDamagePageNumber: 1,
  complaintJobsPageNumber: 1,
  servicePlacesPageNumber: 1,
  partnerAttendenceSelectedTab: "On-job",
  dailyCleaningJobSelectedTab: "Not Attempted",
  onDemandJobSelectedTab: "Not Attempted",
  apartmentDetailsSelectedTab: "Apartment Details",
  carBrandsPageNumber: 1,
  carModelsPageNumber: 1,
  servicePackagePageNumber: 1,
  bikeBrandsPageNumber: 1,
  bikeModelsPageNumber: 1,
  filterAtNewDailyCleaningBookings: null,
  onDemandNewBookingCount: 0,
  reportedJobsApprovedPageNumber: 1,
  reportedJobsNotApprovedPageNumber: 1,
}

const AppState = (state = initialState, action) => {
  switch (action.type) {
    case AppStateConstants.CLOSED_TICKET_PAGE_COUNT:
      return {
        ...state,
        closedTicketCount: action.closedTicketCount,
      }
    case AppStateConstants.OPEN_TICKET_PAGE_COUNT:
      return {
        ...state,
        openTicketPageCount: action.openTicketPageCount,
      }
    case AppStateConstants.HOLD_TICKET_PAGE_COUNT:
      return {
        ...state,
        holdTicketPageCount: action.holdTicketPageCount,
      }
    case AppStateConstants.ON_DEMAND_NEW_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandNewBookingPageCount: action.onDemandNewBookingPageCount,
      }
    case AppStateConstants.ON_DEMAND_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandAssignedBookingPageCount:
          action.onDemandAssignedBookingPageCount,
      }
    case AppStateConstants.ON_DEMAND_UN_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandUnAssignedBookingPageCount:
          action.onDemandUnAssignedBookingPageCount,
      }
    case AppStateConstants.ON_DEMAND_NOT_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandNotAssignedBookingPageCount:
          action.onDemandNotAssignedBookingPageCount,
      }
    case AppStateConstants.ON_DEMAND_INACTIVE_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandInactiveBookingPageCount:
          action.onDemandInactiveBookingPageCount,
      }
    case AppStateConstants.ON_DEMAND_CANCELED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        onDemandCanceledBookingPageCount:
          action.onDemandCanceledBookingPageCount,
      }
    case AppStateConstants.DAILY_CLEANING_NEW_BOOKING_PAGE_COUNT:
      return {
        ...state,
        dailyCleaningNewBookingPageCount:
          action.dailyCleaningNewBookingPageCount,
      }
    case AppStateConstants.DAILY_CLEANING_ASSIGNED_BOOKING_PAGE_COUNT:
      return {
        ...state,
        dailyCleaningAssignedBookingPageCount:
          action.dailyCleaningAssignedBookingPageCount,
      }
    case AppStateConstants.DAILY_CLEANING_INACTIVE_BOOKING_PAGE_COUNT:
      return {
        ...state,
        dailyCleaningInactiveBookingPageCount:
          action.dailyCleaningInactiveBookingPageCount,
      }
    case AppStateConstants.ALL_USERS_PAGE_COUNT:
      return {
        ...state,
        allUsersPageCount: action.allUsersPageCount,
      }
    case AppStateConstants.ALL_PARTNERS_PAGE_COUNT:
      return {
        ...state,
        allPartnersPageCount: action.allPartnersPageCount,
      }
    case AppStateConstants.NOT_ATTEPMTED_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        notAttemtedDailyCleaningJobPageNumber:
          action.notAttemtedDailyCleaningJobPageNumber,
      }
    case AppStateConstants.COMPLETED_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        completedDailyCleaningJobPageNumber:
          action.completedDailyCleaningJobPageNumber,
      }
    case AppStateConstants.APPROVAL_PENDING_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        approvalPendingDailyCleaningJobPageNumber:
          action.approvalPendingDailyCleaningJobPageNumber,
      }
    case AppStateConstants.NOT_CLEAN_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        notCleanDailyCleaningJobPageNumber:
          action.notCleanDailyCleaningJobPageNumber,
      }
    case AppStateConstants.NO_VEHICLE_DAILY_CLEANING_JOB_PAGE_NUMBER:
      return {
        ...state,
        noVehicleDailyCleaningPageNumber:
          action.noVehicleDailyCleaningPageNumber,
      }

    case AppStateConstants.SCHEDULED_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        scheduledOnDemandJobPageNumber: action.scheduledOnDemandJobPageNumber,
      }
    case AppStateConstants.STARTED_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        startedOnDemandJobPageNumber: action.startedOnDemandJobPageNumber,
      }
    case AppStateConstants.COMPLETED_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        completedOnDemandJobPageNumber: action.completedOnDemandJobPageNumber,
      }
    case AppStateConstants.APPROVAL_PENDING_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        approvalPendingOnDemandJobPageNumber:
          action.approvalPendingOnDemandJobPageNumber,
      }
    case AppStateConstants.NOT_CLEAN_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        notCleanOnDemandJobPageNumber: action.notCleanOnDemandJobPageNumber,
      }
    case AppStateConstants.NO_VEHICLE_ON_DEMAND_JOB_PAGE_NUMBER:
      return {
        ...state,
        noVehicleOnDemandPageNumber: action.noVehicleOnDemandPageNumber,
      }

    case AppStateConstants.LOGGED_IN_USER_DETAILS:
      return {
        ...state,
        logged_in_user_details: action.logged_in_user_details,
      }
    case AppStateConstants.PARTNER_ATTENDANCE_STARTED_PAGE_NUMBER:
      return {
        ...state,
        partnerAttendenceStartedPageNumber:
          action.partnerAttendenceStartedPageNumber,
      }
    case AppStateConstants.PARTNER_ATTENDANCE_PRESENT_PAGE_NUMBER:
      return {
        ...state,
        partnerAttendencePresentPageNumber:
          action.partnerAttendencePresentPageNumber,
      }
    case AppStateConstants.PARTNER_ATTENDANCE_ABSENT_PAGE_NUMBER:
      return {
        ...state,
        partnerAttendenceAbsentPageNumber:
          action.partnerAttendenceAbsentPageNumber,
      }
    case AppStateConstants.PARTNER_ATTENDENCE_SELECTED_TAB:
      return {
        ...state,
        partnerAttendenceSelectedTab: action.partnerAttendenceSelectedTab,
      }
    case AppStateConstants.APARTMENT_DETAILS_SELECTED_TAB:
      return {
        ...state,
        apartmentDetailsSelectedTab: action.apartmentDetailsSelectedTab,
      }
    case AppStateConstants.DAILY_CLEANING_JOB_SELECTED_TAB:
      return {
        ...state,
        dailyCleaningJobSelectedTab: action.dailyCleaningJobSelectedTab,
      }
    case AppStateConstants.ON_DEMAND_JOB_SELECTED_TAB:
      return {
        ...state,
        onDemandJobSelectedTab: action.onDemandJobSelectedTab,
      }

    case AppStateConstants.REPORTED_DAMAGE_PAGE_NUMBER:
      return {
        ...state,
        reportedDamagePageNumber: action.reportedDamagePageNumber,
      }
    case AppStateConstants.COMPLAINT_JOBS_PAGE_NUMBER:
      return {
        ...state,
        complaintJobsPageNumber: action.complaintJobsPageNumber,
      }
    case AppStateConstants.SERVICE_PLACES_PAGE_NUMBER:
      return {
        ...state,
        servicePlacesPageNumber: action.servicePlacesPageNumber,
      }
    case AppStateConstants.SET_CAR_BRANDS_PAGE_NUMBER:
      return {
        ...state,
        carBrandsPageNumber: action.carBrandsPageNumber,
      }
    case AppStateConstants.SET_CAR_MODELS_PAGE_NUMBER:
      return {
        ...state,
        carModelsPageNumber: action.carModelsPageNumber,
      }
    case AppStateConstants.SERVICE_PACKAGE_PAGE_NUMBER:
      return {
        ...state,
        servicePackagePageNumber: action.servicePackagePageNumber,
      }
    case AppStateConstants.SET_BIKE_BRANDS_PAGE_NUMBER:
      return {
        ...state,
        bikeBrandsPageNumber: action.bikeBrandsPageNumber,
      }
    case AppStateConstants.SET_BIKE_MODELS_PAGE_NUMBER:
      return {
        ...state,
        bikeModelsPageNumber: action.bikeModelsPageNumber,
      }
    case AppStateConstants.FILTER_AT_NEW_DAILY_CLEANING_BOOKINGS:
      return {
        ...state,
        filterAtNewDailyCleaningBookings:
          action.filterAtNewDailyCleaningBookings,
      }
    case AppStateConstants.ON_DEMAND_NEW_BOOKING_COUNT:
      return {
        ...state,
        onDemandNewBookingCount: action.onDemandNewBookingCount,
      }
    case AppStateConstants.REPORTED_JOBS_APPROVED_PAGE_NUMBER:
      return {
        ...state,
        reportedJobsApprovedPageNumber: action.reportedJobsApprovedPageNumber,
      }
    case AppStateConstants.REPORTED_JOBS_NOT_APPROVED_PAGE_NUMBER:
      return {
        ...state,
        reportedJobsNotApprovedPageNumber:
          action.reportedJobsNotApprovedPageNumber,
      }

    default:
      return state
  }
}
export default AppState
