import React from "react"
import {Row, Col, Card, OverlayTrigger, Tooltip} from "react-bootstrap"
import {Link} from "react-router-dom"
import callApi from "../../../utils/apiCaller"
import moment from "moment"

const AddressDetailsInBookings = (props) => {
  const [data, setData] = React.useState(props.data.customerId || {})
  const [isEditMode, setIsEditMode] = React.useState(false)

  const handleUpdate = () => {
    if (props.data._id) {
      const info = {
        servicebookingId: props.data._id,
        parking_slot: data.parking_slot,
        pillar_no: data.pillar_no,
      }
      callApi("servicebooking/updatebooking", "POST", info).then((res) => {
        setIsEditMode(false)
        props.getData(props.data._id)
      })
    }
  }

  return (
    <Card>
      <Card.Body>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <h4 className="header-title mb-3">User details</h4>
          {false && (
            <Link
              to="#"
              className="action-icon"
              onClick={() => setIsEditMode(true)}
            >
              <i className="mdi mdi-square-edit-outline">Edit</i>
            </Link>
          )}
        </div>
        <div className="table-responsive">
          <table className="table mb-0">
            <thead className="table-light">
              <tr>
                <th>Title</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{data?.name}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{data?.email}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{data?.phone}</td>
              </tr>
              <tr>
                <td>OTP</td>
                <td>{data?.otp}</td>
              </tr>
              <tr>
                <td>Date of Joined</td>
                <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {isEditMode && (
          <div className="text-end">
            <button
              className="btn btn-danger mt-2"
              style={{marginRight: "20px"}}
              onClick={() => setIsEditMode(false)}
            >
              <i className="mdi mdi-content-save"></i>Cancel
            </button>
            <button
              className="btn btn-success mt-2 pl-2 "
              onClick={() => handleUpdate()}
            >
              <i className="mdi mdi-content-save"></i> Save
            </button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default AddressDetailsInBookings
