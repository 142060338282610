import React, { useState } from "react"
import _, { toUpper } from "lodash"
import moment from "moment"
import { Row, Col, Card, Button, Table, Dropdown, Modal } from "react-bootstrap"
import classNames from "classnames"
import { Link, useNavigate } from "react-router-dom"
import AssignCleanerComponent from "../AssignCleanerComponent"
import OnDemandSchedule from "../../../../components/OnDemandSchedule"

const BookingDetailsLeftCard = (props) => {
  const navigate = useNavigate()
  const data = props.data || {}
  // const addons = props.addons || []
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  let fillColor =
    data?.specialTask?.status === "Not Attempted"
      ? "#030201"
      : data?.specialTask?.status === "Completed"
        ? "#2A882A"
        : data?.specialTask?.status === "Cleaner did not clean"
          ? "#FF0000"
          : "#0181E3"

  let DailyFillColor = data?.status === "Active" ? "#2A882A" : "#0181E3"

  return (
    <>
      <Card>
        <Card.Body>
          <div>
            <Col xl={12} className="pb-3">
              <>
                <h5 className="">
                  <i className="mdi mdi-account  me-1"></i>Customer details
                </h5>
                <div className="ms-3">
                  <Row className="text-muted mb-1 ">
                    <Col md={3}>
                      <span
                        style={{
                          color: "#00000090",
                          fontSize: "16px",
                        }}
                      >
                        Customer name :
                      </span>
                    </Col>
                    <Col>
                      <Link
                        to={{
                          pathname: "/users/user-details",
                          search: `?id=${data?.customerId?._id}`,
                        }}
                      // target="_blank"
                      >
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 text-decoration-underline "
                        >
                          {data?.customerId?.name}
                        </strong>
                        <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="text-muted font-1">
                    <Col md={3}>
                      <span
                        style={{
                          color: "#00000090",
                          fontSize: "16px",
                        }}
                      >
                        Customer number :
                      </span>
                    </Col>
                    <Col>
                      <strong
                        style={{
                          color: "#2b2d2f",
                          fontSize: "15px",
                        }}
                        className="ms-2 "
                      >
                        {data?.customerId?.phone}
                      </strong>
                    </Col>
                  </Row>
                </div>
              </>
              <>
                <div
                  className="d-flex mt-2"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <h5 className="">
                    <i className="mdi mdi-account mdi-clipboard-text me-1"></i>
                    Booking details
                  </h5>
                  {data?.isRescheduled ?
                    <span className="badge ms-2 badge-warning-color bg-warning">Rescheduled</span>
                    :
                    null
                  }
                </div>
                <div className="ms-3">
                  <Row className="text-muted mb-1 ">
                    <Col md={3}>
                      <span
                        style={{
                          fontSize: "16px",
                          color: "#00000090",
                        }}
                      >
                        Status :
                      </span>
                    </Col>
                    <Col>
                      {data?.isSubscriptionModel ? (
                        <>
                          <span
                            className={
                              data.isCancelled
                                ? "badge ms-2 badge-danger-color bg-danger"
                                : data.isActive
                                  ? "badge ms-2 badge-success-color bg-success"
                                  : "badge ms-2 badge-warning-color bg-warning"
                            }
                            style={{ fontSize: 12 }}
                          >
                            {data.isCancelled
                              ? "Cancelled"
                              : data.isActive
                                ? "Active"
                                : "Inactive"}
                          </span>
                        </>
                      ) : (
                        <>
                          {!data.isActive &&
                            data?.isClosed &&
                            data?.isCancelled ? (
                            <span
                              className="badge ms-2 badge-danger-color bg-danger"
                              style={{ fontSize: 12 }}
                            >
                              Cancelled
                            </span>
                          ) : (
                            <>
                              {data?.specialTask?.status ? (
                                <span>
                                  <strong
                                    style={{
                                      fontSize: 12,
                                      backgroundColor: fillColor,
                                    }}
                                    className="ms-2 badge"
                                  >
                                    {data?.specialTask?.status}{" "}
                                  </strong>
                                </span>
                              ) : (
                                <strong
                                  style={{
                                    fontSize: 12,
                                    backgroundColor: fillColor,
                                  }}
                                  className="ms-2 badge"
                                >
                                  Not scheduled
                                </strong>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  {false && data?.isRescheduled ?
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#00000090",
                          }}
                        >
                          Old Service Date :
                        </span>
                      </Col>
                      <Col>
                        <div
                          onClick={() => {
                            setShowRescheduleModal(true);
                          }}
                        >
                          <strong
                            style={{
                              color: "#2b2d2f",
                              fontSize: "15px",
                            }}
                            className="ms-2 text-decoration-underline "
                          >
                            Reschedule from date
                          </strong>
                          <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                        </div>
                      </Col>
                    </Row>
                    :
                    null}
                  {data?.servicepackageId?.service_name && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Service name :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {data?.servicepackageId?.service_name}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.timeSlot?.name && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Time slot :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {data?.timeSlot?.name}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.service_booking_date && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Booking Date :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {moment(data?.service_booking_date).format(
                            "DD MMM, YYYY"
                          )}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.isSubscriptionModel ? (
                    <>
                      {data?.service_start_date && (
                        <Row className="text-muted mb-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Start Date :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{
                                color: "#2b2d2f",
                                fontSize: "15px",
                              }}
                              className="ms-2 "
                            >
                              {moment(data?.service_start_date).format(
                                "DD MMM, YYYY"
                              )}
                            </strong>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : (
                    <>
                      {data?.specialTask?.due_date && (
                        <div>
                          <Row className="text-muted mb-1 ">
                            <Col md={3}>
                              <span
                                style={{
                                  color: "#00000090",
                                  fontSize: "16px",
                                }}
                              >
                                {data?.serviceType == "cleaning" ? "Start Date :" : "Service Date :"}
                              </span>
                            </Col>
                            <Col>
                              <strong
                                style={{
                                  color: "#2b2d2f",
                                  fontSize: "15px",
                                }}
                                className="ms-2 "
                              >
                                {moment(data?.specialTask?.due_date).format(
                                  "DD MMM, YYYY"
                                )}
                              </strong>
                            </Col>
                          </Row>
                          {data?.serviceType == "on-demand" ?
                            <Row>
                              <Col md={3}>
                                <span
                                  style={{
                                    color: "#00000090",
                                    fontSize: "16px",
                                  }}
                                >
                                  Service Time :
                                </span>
                              </Col>
                              <Col>
                                <strong
                                  style={{
                                    color: "#2b2d2f",
                                    fontSize: "15px",
                                  }}
                                  className="ms-2 "
                                >
                                  {data?.serviceType != "cleaning" ?
                                    moment().startOf('day').add(data?.serviceMinute, 'minutes').format(
                                      "LT"
                                    )
                                    :
                                    null
                                  }
                                </strong>
                              </Col>
                            </Row>
                            :
                            null
                          }

                        </div>
                      )}
                    </>
                  )}
                  {!moment(data?.service_reactivation_date).isSame(
                    moment(data?.service_start_date)
                  ) && (
                      <>
                        {data?.service_reactivation_date && (
                          <Row className="text-muted mb-1 ">
                            <Col md={3}>
                              <span
                                style={{
                                  color: "#00000090",
                                  fontSize: "16px",
                                }}
                              >
                                Reactivation Date :
                              </span>
                            </Col>
                            <Col>
                              <strong
                                style={{
                                  color: "#2b2d2f",
                                  fontSize: "15px",
                                }}
                                className="ms-2 "
                              >
                                {moment(data?.service_reactivation_date).format(
                                  "DD MMM, YYYY"
                                )}
                              </strong>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  {data?.isHold && <></>}
                  {data?.isCancelled && data?.cancelledOn && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Cancelled Date :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {moment(data?.cancelledOn).format("DD MMM, YYYY")}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  {data?.serviceType == "cleaning" && data?.expiry_date && (
                    <Row className="text-muted mb-1 ">
                      <Col md={3}>
                        <span
                          style={{
                            color: "#00000090",
                            fontSize: "16px",
                          }}
                        >
                          Expiry Date :
                        </span>
                      </Col>
                      <Col>
                        <strong
                          style={{
                            color: "#2b2d2f",
                            fontSize: "15px",
                          }}
                          className="ms-2 "
                        >
                          {moment(data?.expiry_date).format("DD MMM, YYYY")}
                        </strong>
                      </Col>
                    </Row>
                  )}
                </div>
              </>
              <>
                <h5 className="mt-3">
                  <i className="mdi mdi-account-wrench me-1"></i>Partner details
                </h5>
                <div className="ms-3">
                  {data?.agencyId?.role === "AGENCY_OWNER" ? (
                    <>
                      {data?.agencyId?.name && (
                        <Row className="text-muted font-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Agency Name :
                            </span>
                          </Col>
                          <Col>
                            <Link
                              to={{
                                pathname: "/partners/partner-profile",
                                search: `?id=${data?.agencyId?.ownerId}`,
                              }}
                            // target="_blank"
                            >
                              <strong
                                style={{
                                  color: "#00000090",
                                }}
                                className="ms-2 text-decoration-underline "
                              >
                                {data?.agencyId?.name}
                              </strong>
                              <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                            </Link>
                          </Col>
                        </Row>
                      )}
                      {data?.agencyId?.owner_phone && (
                        <Row className="text-muted mb-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Agency number :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{
                                color: "#00000090",
                              }}
                              className="ms-2 "
                            >
                              {data?.agencyId?.owner_phone}
                            </strong>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : null}

                  {data?.assigned_cleaner_id ? (
                    <>
                      <Row className="text-muted mb-1 font-1 ">
                        <Col md={3}>
                          <span
                            style={{
                              color: "#00000090",
                              fontSize: "16px",
                            }}
                          >
                            Partner name :
                          </span>
                        </Col>
                        <Col>
                          <Link
                            to={{
                              pathname:
                                data?.assigned_cleaner_id?.role ===
                                  "AGENCY_PARTNER"
                                  ? "/partners/staff-details"
                                  : "/partners/partner-profile",
                              search: `?id=${data?.assigned_cleaner_id?._id}`,
                            }}
                          // target="_blank"
                          >
                            <strong
                              style={{
                                color: "#2b2d2f",
                                fontSize: "15px",
                              }}
                              className="ms-2 text-decoration-underline "
                            >
                              {data?.assigned_cleaner_id?.name}
                            </strong>
                            <i className=" mdi mdi-arrow-top-right-thin-circle-outline ms-1"></i>
                          </Link>
                        </Col>
                      </Row>
                      {data?.assigned_cleaner_id?.phone && (
                        <Row className="text-muted mb-1 font-1">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Partner number :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{
                                color: "#2b2d2f",
                                fontSize: "15px",
                              }}
                              className="ms-2 "
                            >
                              {data?.assigned_cleaner_id?.phone}
                            </strong>
                          </Col>
                        </Row>
                      )}
                      {data?.assigned_cleaner_id?.role && (
                        <Row className="text-muted mb-1 ">
                          <Col md={3}>
                            <span
                              style={{
                                color: "#00000090",
                                fontSize: "16px",
                              }}
                            >
                              Partner type :
                            </span>
                          </Col>
                          <Col>
                            <strong
                              style={{ fontSize: 10 }}
                              className="badge badge-outline-primary ms-2"
                            >
                              {data?.assigned_cleaner_id?.role === "INDEPENDENT"
                                ? "Independent"
                                : "Agency"}
                            </strong>
                          </Col>
                        </Row>
                      )}
                      {data?.specialTask?.start_job_otp && (
                        <Row className="text-muted pb-1 font-1 ">
                          <Col xl={3}>
                            <span style={styles.text}>Start job OTP :</span>
                          </Col>
                          <Col>
                            <strong style={styles.textBlack} className="ms-2 ">
                              {data?.specialTask?.start_job_otp}
                            </strong>
                          </Col>
                        </Row>
                      )}
                      {/* {data?.specialTask?.end_job_otp && (
                      <Row className="text-muted pb-1 font-1 ">
                        <Col xl={3}>
                          <span style={styles.text}>End job OTP :</span>
                        </Col>
                        <Col>
                          <strong style={styles.textBlack} className="ms-2 ">
                            {data?.specialTask?.end_job_otp}
                          </strong>
                        </Col>
                      </Row>
                    )} */}
                      <>
                        {data?.isScheduled && !data?.isCancelled && (
                          <div>
                            <button
                              className="btn"
                              style={{
                                backgroundColor: "#008CBA",
                                color: "white",
                                borderRadius: 20,
                                height: 30,
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                marginTop: 10,
                                marginLeft: 6,
                              }}
                              onClick={() => {
                                if (data?.serviceType == "cleaning" || data?.serviceType == "addon") {
                                  setShowAssignModal(true)
                                }
                                else {
                                  props?.handleShowAvailablePartners(true);
                                }
                              }}
                            >
                              {data.assigned_cleaner_id
                                ? " Reassign Partner"
                                : " Assign Partner"}
                            </button>
                          </div>
                        )}
                      </>
                    </>
                  ) : (
                    <>
                      <strong
                        style={{ fontSize: 12, backgroundColor: "#FF000099" }}
                        className="badge"
                      >
                        Partner Not Assigned
                      </strong>
                      {data?.isScheduled && !data?.isCancelled && (
                        <div>
                          <button
                            className="btn"
                            style={{
                              backgroundColor: "#008CBA",
                              color: "white",
                              borderRadius: 20,
                              height: 30,
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              marginTop: 10,
                            }}
                            onClick={() => {
                              if (data?.serviceType == "cleaning" || data?.serviceType == "addon") {
                                setShowAssignModal(true)
                              }
                              else {
                                props?.handleShowAvailablePartners(true);
                              }
                            }}
                          >
                            {data.assigned_cleaner_id
                              ? "Reassign Partner"
                              : "Assign Partner"}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            </Col>
            {showAssignModal && (
              <AssignCleanerComponent
                name={
                  data.assigned_cleaner_id ? "Reassign Partner" : "Assign Partner"
                }
                data={data}
                onHide={() => {
                  setShowAssignModal(false)
                }}
                getData={props.getData}
                reload={() => {
                  setShowAssignModal(false)
                }}
              />
            )}
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={showRescheduleModal}
        onHide={() => {
          setShowRescheduleModal(false);
        }}
        size="lg"
      >
        <Modal.Header>
          Reschedule Details
        </Modal.Header>
        <Modal.Body>
          <h4>Recent Reschedule Details</h4>
          <Row>
            <Col md={4}>
              Reschedule on :
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              Rescheduled from :
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              Rescheduled to :
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              Reschedule by :
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              Reschedule reason :
            </Col>
          </Row>
          <div>
            <h3>Reschedule History</h3>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Reschedule on</th>
                  <th>Reschedule from</th>
                  <th>Reschedule to</th>
                  <th>by</th>
                  <th>reason</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>10-12-2023</td>
                  <td><span>10-12-2023</span><small className="ms-1">10:30 PM</small></td>
                  <td><span>10-12-2023</span><small className="ms-1">10:30 PM</small></td>
                  <td>admin</td>
                  <td>reason</td>

                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const styles = {
  text: {
    color: "#00000090",
    fontSize: "16px",
  },
  textBlack: {
    color: "#2b2d2f",
    fontSize: "15px",
  },
}

export default BookingDetailsLeftCard
